type FaqsType = {
  question: string;
  answers: string[];
};

export const faqsData: FaqsType[] = [
  {
    question: `من هي كارترست ؟`,
    answers: [
      `اهلا عزيزتي/ي،كارترست هي شركة سعودية رائدة متخصصة في مجال خدمات المركبات المستعملة، وتشمل خدماتها تسعير المركبات والفحص والضمان. سعدنا بخدمتك `,
    ],
  },
  {
    question: `ما هي الخدمات التي تقدمها كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي،تقدّم كارترست لعملائها الخدمات التالية:
      برنامج كارترست للمركبات المستعملة المعتمدة.
      خدمة تقييم أسعار المركبات حسب سعر السوق.
      الفحص الشامل للمركبة قبل الشراء.
      ضمان كارترست الممتد للمركبة.
      ضمان كارترست الممتد للمركبات المستوردة.
      خدمة استصدار شهادة "موجز".سعدنا بخدمتك`,
    ],
  },
  {
    question: `هل تقوم كارترست ببيع المركبات؟`,
    answers: [
      `اهلا عزيزتي/ي،لا، وإنما توفر كارترست خدمات موجهة لسوق المركبات المستعملة.`,
    ],
  },
  {
    question: `هل تقوم كارترست بشراء المركبات المستعملة؟`,
    answers: [
      `اهلا عزيزتي/ي،لا، وإنما توفر كارترست خدمات موجهة لسوق المركبات المستعملة.`,
    ],
  },
  {
    question: `كم تبلغ تكلفة خدمات كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي،تعتمد التكلفة بناء على ما يطلبه العميل؛ فبالنسبة لخدمة تقييم أسعار المركبات فإن السعر هو 25 ريالاً، وخدمة الحصول على شهادة ضمان كارترست للمركبة المستعملة المعتمدة، فإن الأسعار تتراوح ما بين 1300 إلى 2100 ريال للحصول على شهادة الاعتماد والضمان، وأما بالنسبة لتكلفة الفحص المسبق للمركبة المستعملة ما قبل الشراء فالتكلفة ما بين 550 إلى 1600 ريال سعودي. سعدنا بخدمتك`,
    ],
  },
  {
    question: `أين تقع مراكز كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي، يمكنك الحصول على خدمات تقييم أسعار المركبات الكترونياً عن طريق الموقع او تطبيق كارترست، أما بالنسبة لخدمات الفحص والضمان فيمكنك زيارة أحد فروع بترومين إكسبرس المختارة أو طلب الخدمة المنزلية؛ حيث ستصلك ورشتنا المتنقلة لخدمتك في موقعك الذي تحدده.`,
    ],
  },
  {
    question: `هل تقومون بفحص المركبات القديمة؟ وإلى أي موديل؟`,
    answers: [
      `اهلا عزيزتي/ي،نعم، نقوم بفحص وتقييم أي مركبة قديمة مهما كان عمرها. سعدنا بخدمتك`,
    ],
  },
  {
    question: `هل تقومون بإصدار شهادات اعتماد للمركبات القديمة؟ وإلى أي موديل؟`,
    answers: [
      `اهلا عزيزتي/ي، نعم يمكننا اعتماد أي مركبة في لكن تحت شرطين ان لا يتجاوزعمر المركبة 10 سنوات و ان لا تكون المسافة المقطوعة اكثر من 200 الف كيلومترو ذلك بعد فحصها واجتيازها لمعاييرنا الموضحة أدناه.سعدنا بخدمتك `,
    ],
  },
  {
    question: `كم هي مدة الضمان الذي تصدرونه، وهل هناك عدة مستويات؟`,
    answers: [
      `اهلا عزيزتي/ي،شهادات الاعتماد والضمان التي تصدرها كارترست سنه و يمكن تجديدها و يوجد 3 فئات مخنلفة من التغطية لمزيد من المعلومات حمل التطبيق او قم بزياره موقعنا. 
      سعدنا بخدمتك`,
    ],
  },
  {
    question: `كم من الوقت يستغرق فحص المركبة؟ ولماذا تأخذ وقتاً طويلاً جداً؟`,
    answers: [
      `اهلا عزيزتي/ي، إن عملية الفحص لدى كارترست تستغرق٦٠ دقيقة فقط، وقد تأخذ الأعمال التقنية من ٣٠ إلى ٤٠ دقيقة. وأما اختبارات أداء المركبة على الطريق فتأخذ عادة من 5 إلى 15 دقيقة فقط. نحن نحرص على أن نقدم لك أفضل مستويات الخدمة من خلال فحص المركبة، والتي تغطي كافة النواحي، لنقدم لك تقريراً شاملاً وموثوقاً عن المركبة. سعدنا بخدمتك`,
    ],
  },
  {
    question: `هل تقومون باعتماد كافة أنواع المركبات؟`,
    answers: [
      `اهلا عزيزتي/ي،هناك شروط خاصة للحصول على شهادات اعتماد كارترست:
      يجب أن لا يتجاوز عمر المركبة (الموديل) عشر سنوات.
      يجب أن لا تكون المركبة قد قطعت أكثر من 200 ألف كيلومتر.
      يجب أن يكون لطراز المركبة وكيلاً معتمداً للشركة الصانعة في المملكة العربية السعودية.سعدنا بخدمتك`,
    ],
  },
  {
    question: `هل تقومون بالتعامل مع أحد شركات التأمين؟`,
    answers: [
      `أهلا عزيزتي/ي، تتم خدمة ضمان كارترست بالتعاون مع التعاونية للتأمين.`,
    ],
  },
  {
    question: `هل تمنحون شهادات ضمان / معتمدة لكافة أنواع المركبات؟`,
    answers: [
      `اهلا عزيزتي/ي،بالنسبة للضمان فإنه يتم استصداره من قبل الشركات المتخصصة والمرخصة لضمان المركبات، وذلك فقط للمركبة التي تجتاز عمليات الاختبار وتفي بشروط الحصول على شهادة الثقة حسب معايير كارترست الموضحة فيما سبق.سعدنا بخدمتك`,
    ],
  },
  {
    question: `هل تمنحون شهادة ضمان للموديلات القديمة؟`,
    answers: [
      `اهلا عزيزتي/ي، نعم، ولكن على أن لا تتجاوز عمر المركبة (الموديل) عشر سنوات.سعدنا بخدمتك`,
    ],
  },
  {
    question: `ما هي المزايا التي أحصل عليها من فحص المركبة قبل الشراء؟`,
    answers: [
      `اهلا عزيزتي/ي،سوف تحصل على مزيد من المعلومات حول حالة المركبة قبل أن تتخذ قرار الشراء.
      سوف تحصل على تحليل مفصّل بناء على جميع منهجيات كارترست الأربع:
      الفحص والتفتيش البصري.
      تقنيات الفحص بالكمبيوتر.
      الفحص الميكانيكي.
      وكذلك ستحصل على ألبوم صور للمركبة.
      سوف تحصل على تقرير الفحص الشامل الذي يغطي 110 نقاط في المركبة و يشمل تقرير موجز .
      ستحصل على التقييم العام والدقيق لحالة المركبة.
      سوف تتعرف على الإصلاحات اللازمة للمركبة مع التوصيات الضرورية. 
      تأهيل المركبة للحصول على شهادة الاعتماد وأهلية الضمان.سعدنا بخدمتك `,
    ],
  },
  {
    question: `ما هي المزايا التي أحصل عليها من شهادة اعتماد كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي، تقرير مفصل عن المركبة مع الإصلاحات المستقبلية المطلوبة.
      ارتفاع بنسبة (5 – 20%) تقريباً في قيمة المركبة عند إعادة بيعها في حال تم التصديق عليها واعتمادها.
      إمكانية تمديد الضمان ضد أعطال المركبة لمدة 6 أشهر أو 10 آلاف كيلومتر، أيهما يأتي أولاً.
      تعزيز فرصة بيع المركبة لكونها معتمدة وتحت الضمان.
      مطالبات الضمان غير محدودة.
      راحة البال والاطمئنان. سعدنا بخدمتك `,
    ],
  },
  {
    question: `ما الفرق بين شهادة فحص المركبة قبل الشراء وشهادة اعتماد كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي، تطبق جميع مراحل الفحوصات والاختبارات في كلا الحالتين، ولكن في حالة الفحص قبل الشراء فإن التقرير يصدر بكافة التفاصيل بدون شهادة الضمان. سعدنا بخدمتك `,
    ],
  },
  {
    question: `ما الذي يجعلني أثق بكارترست؟`,
    answers: [
      `اهلا عزيزتي/ي، توفر لك كارترست عملية فحص شاملة بجودة عالية، تغطي جميع جوانب وأجزاء المركبة، وتزودك بتقرير كامل ومفصل. كما إن عملية الاعتماد الخاصة بنا تتم باستخدام أحدث التقنيات المعتمدة من Intertek المرموقة، وتطابق معايير شهادة الجودة للمواصفات ISO 17020.سعدنا بخدمتك `,
    ],
  },
  {
    question: `هل تقدمون خدمات إصلاح وصيانة المركبات؟`,
    answers: [
      `اهلا عزيزتي/ي، لا، لانقوم في كارترست بإصلاح المركبات؛ ولكننا نوفر لك تقرير فحص شامل ومفصل يسلط الضوء على الإصلاحات المطلوبة، والتوصيات من قبل شركاؤنا المناسبين في مجالات الصيانة، مع أفضل عروض التكلفة.سعدنا بخدمتك `,
    ],
  },
  {
    question: `هل تقدمون شهادة الاعتماد مطبوعة؟`,
    answers: [
      `اهلا عزيزتي/ي، نقدم لك شهادة الاعتماد إلكترونياً عبر الإنترنت حيث يمكنك طباعتها فوريا  وذلك حفاظاً على البيئة.
      سعدنا بخدمتك`,
    ],
  },
  {
    question: `ما الأوراق المطلوبة مني لفحص المركبة؟`,
    answers: [`اهلا عزيزتي/ي، فقط رخصة ملكية المركبة (الاستمارة).سعدنا بخدمتك`],
  },
  {
    question: `ما الحل إذا فشلت المركبة باجتياز اختبارات التقييم للحصول على شهادة اعتماد كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي، سوف تحصل على تقرير مفصل يغطي الإصلاحات "التي يجب عليك القيام بها" من أجل اجتياز مركبتك واعتمادها.
      يُمنح أصحاب المركبات فترة سماح مدتها 30 يوماً لإجراء الإصلاحات المطلوبة، ومن ثم إعادة المحاولة مجاناً من خلال الموقع المحدد.
      فإذا اجتازت المركبة الاختبارات تحصل على شهادة الاعتماد.
      أما في حال فشلت المركبة مرة أخرى، فسوف يتم خصم رسوم الفحص فقط، وإعادة رسوم الضمان للعميل. سعدنا بخدمتك`,
    ],
  },
  {
    question: `ما الذي يحصل عليه العميل عند اعتماد مركبته؟`,
    answers: [
      ` اهلا عزيزتي/ي،شهادة اعتماد كارترست. "CarTrust CPO"
      شهادة ضمان المركبة.
      فإذا اجتازت المركبة الاختبارات تحصل على شهادة الاعتماد.
      تقرير مفصل عن حالة المركبة بما في ذلك الإصلاحات المطلوبة مع التقديرات (إن وجدت).
      شهادة "موجز".سعدنا بخدمتك`,
    ],
  },
  {
    question: `ما الذي يغطيه ضمان المركبة وما هي مزاياه؟`,
    answers: [
      `اهلا عزيزتي/ي، الضمان يشمل: المحرك، ناقل الحركة، الكهرباء، الديفرنس، مجموعة الطاقة ومكيف الهواء.
      يتمتع الضمان بمطالبات غير محدودة.
      التغطية تصل إلى 25 ألف ريال سعودي.
      يمكن للعميل تمديد الضمان حتى 3 سنوات.
      يمكن إجراء الإصلاحات لدى الوكيل أو مراكز الخدمة المعتمدة.سعدنا بخدمتك`,
    ],
  },
  {
    question: `كيف يمكنني الحصول على خدمات كارترست؟`,
    answers: [
      `اهلا عزيزتي/ي، يمكنك الحصول على خدمات كارترست من خلال القنوات التالية:
      حجز موعد عبر موقعنا على الإنترنت: cartrust.sa
      حجز موعد عبر تطبيقنا: CarTrust
      الحجز عبر الاتصال بمركز الاتصال الموحد 9200 33331
      أو تفضل بزيارة احد فروع بترومين إكسبريس المختارة . سعدنا بخدمتك`,
    ],
  },
  {
    question: `هل توفر كارترست خدمة مميزة VIP؟`,
    answers: [
      `اهلا عزيزتي/ي، نعم، إذ يمكنك طلب الخدمة المميزة؛ حيث ستصلك ورشتنا المتنقلة لخدمتك في موقعك الذي تحدده.سعدنا بخدمتك`,
    ],
  },
];
