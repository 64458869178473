import { default as _91_46_46_46slug_93cA7DtWz1ECMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/[...slug].vue?macro=true";
import { default as About1Footer0vGrlS5duSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/About1Footer.vue?macro=true";
import { default as About1HeroAMOWO4loN5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/About1Hero.vue?macro=true";
import { default as AwardsU7TH3Tbqu5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Awards.vue?macro=true";
import { default as BenefitsmYgPGptQsdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Benefits.vue?macro=true";
import { default as Contactj6Xg02e4AFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Contact.vue?macro=true";
import { default as SocialNetworkIylYS5uyfBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/SocialNetwork.vue?macro=true";
import { default as Teamw51FN1wj6MMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Team.vue?macro=true";
import { default as TeamCard4YFDKd2NCAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/TeamCard.vue?macro=true";
import { default as Testimonialims6iPFAMiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Testimonial.vue?macro=true";
import { default as TestimonialCardgL6hu0ZRocMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/TestimonialCard.vue?macro=true";
import { default as VideoReelwirfelNckJMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/VideoReel.vue?macro=true";
import { default as datal1N4IzYb65Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/data.ts?macro=true";
import { default as indexASlNc1SJMNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/index.vue?macro=true";
import { default as typereCebe7Eo4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/type.ts?macro=true";
import { default as index6aBkD6KqF3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/branches/index.vue?macro=true";
import { default as index8UcgtWJcIhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cancel/index.vue?macro=true";
import { default as indexf7n8ycdseCMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/career/index.vue?macro=true";
import { default as FaqsYZxc3R3033Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Faqs.vue?macro=true";
import { default as FeaturesNX3YM8kk5QMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Features.vue?macro=true";
import { default as Opportunities9gGpDw1txgMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Opportunities.vue?macro=true";
import { default as PlanComparisonxJej4Uc22xMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/PlanComparison.vue?macro=true";
import { default as PricinggUsvB5NWaIMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Pricing.vue?macro=true";
import { default as Saas3HeroYOk0ojpvt8Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Saas3Hero.vue?macro=true";
import { default as Testimonialcu7WtkK2XQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Testimonial.vue?macro=true";
import { default as TestimonialCard266mqlHK8rMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/TestimonialCard.vue?macro=true";
import { default as WorkcT8XwxMaWEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Work.vue?macro=true";
import { default as datayiTSWP83ZAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/data.ts?macro=true";
import { default as indexcMQOENeUGbMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/index.vue?macro=true";
import { default as typeY3oPGj7wm2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/type.ts?macro=true";
import { default as Contacts1Footer1z8aaSTQUFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Contacts1Footer.vue?macro=true";
import { default as Contacts1HeroCxHRmspTVfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Contacts1Hero.vue?macro=true";
import { default as Details0GPnd2bXd2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Details.vue?macro=true";
import { default as Faqsw6DJNQFx5eMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Faqs.vue?macro=true";
import { default as dataIPcNwuhJMDMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/data.ts?macro=true";
import { default as indexkcChzhQQQuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/index.vue?macro=true";
import { default as indexc1urR1DgoDMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/index.vue?macro=true";
import { default as inspectionskQ6flNxqLTMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/inspections.vue?macro=true";
import { default as profile2vqbR5dXhsMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/profile.vue?macro=true";
import { default as transaction77mUT5WhmlMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/transaction.vue?macro=true";
import { default as vehicleskprnyGTuLKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/vehicles.vue?macro=true";
import { default as warrantiesMfIBXHtEpcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/warranties.vue?macro=true";
import { default as ContentdozudMB1KoMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/data-products-disclaimer/components/Content.vue?macro=true";
import { default as indexfzaXNRQUImMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/data-products-disclaimer/index.vue?macro=true";
import { default as BrandsdLFb1QMJTUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Brands.vue?macro=true";
import { default as CarSearchBoxjGY2mtpBVdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/CarSearchBox.vue?macro=true";
import { default as ContactUVacVwSaMiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Contact.vue?macro=true";
import { default as CovidBannerwt9gGes4RqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/CovidBanner.vue?macro=true";
import { default as FeatureMtnT5QvPW2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Feature.vue?macro=true";
import { default as FinancialFooter08vu2Z5eAgMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/FinancialFooter.vue?macro=true";
import { default as FinancialHeroVoo3gg0eboMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/FinancialHero.vue?macro=true";
import { default as IndustrieSwiper9gXHOIhlJlMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/IndustrieSwiper.vue?macro=true";
import { default as NewsCarddO9cAXseA0Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/NewsCard.vue?macro=true";
import { default as NewsSwiperJGq5FoT0fUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/NewsSwiper.vue?macro=true";
import { default as PricingyocKp4jSCYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Pricing.vue?macro=true";
import { default as Purchase4UK0eHBxlrMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Purchase.vue?macro=true";
import { default as ServicesWEq8TbDnLuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Services.vue?macro=true";
import { default as Sponsorsyh4APAtX1iMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Sponsors.vue?macro=true";
import { default as TestimonialKMjm1aXpcGMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Testimonial.vue?macro=true";
import { default as TestimonialCard3HKLL1VFHyMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/TestimonialCard.vue?macro=true";
import { default as data1X8YHCyczeMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/data.ts?macro=true";
import { default as indexI3mMHoxBgLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/index.vue?macro=true";
import { default as evaluation_45types9X3Esx580MMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/types/evaluation-types.ts?macro=true";
import { default as indexh6klPKCAZZMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluations/[id]/index.vue?macro=true";
import { default as ReEvaluationHTiQKLaolhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluations/Components/ReEvaluation.vue?macro=true";
import { default as indexe3TU6yzPOZMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluations/index.vue?macro=true";
import { default as indexh37sdD49hPMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/fail/index.vue?macro=true";
import { default as Contacts1FooterZi3Htr3CjzMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Contacts1Footer.vue?macro=true";
import { default as Contacts1HeroQzUig3iyN0Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Contacts1Hero.vue?macro=true";
import { default as DetailshJUQ6F3cSbMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Details.vue?macro=true";
import { default as FaqsEaSzCmNWdQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Faqs.vue?macro=true";
import { default as dataau2DyfsZzuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/data.ts?macro=true";
import { default as indexRJNTBBhPt9Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/index.vue?macro=true";
import { default as HeroSectionUJCPn94wXtMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/components/HeroSection.vue?macro=true";
import { default as SearchCarENqdh1QUOfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/components/SearchCar.vue?macro=true";
import { default as SpecificationsJI1h1xiWpsMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/components/Specifications.vue?macro=true";
import { default as indexvnC6fLyI8bMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/index.vue?macro=true";
import { default as ContentmbLDiirza4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/impartiality-policy/components/Content.vue?macro=true";
import { default as SinglePostHerodk0V3hx8aqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/impartiality-policy/components/SinglePostHero.vue?macro=true";
import { default as indexw4bVjIkWyCMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/impartiality-policy/index.vue?macro=true";
import { default as indexu3HhzNdD9oMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/index.vue?macro=true";
import { default as CarSearchBoxQ24bUEPgIWMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/CarSearchBox.vue?macro=true";
import { default as ComparisonSliderzezCakPLKAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/ComparisonSlider.vue?macro=true";
import { default as CtaM5i2Z27tRbMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Cta.vue?macro=true";
import { default as FeaturesgGhf98ONVyMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Features.vue?macro=true";
import { default as Features2FrZj3vupN5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Features2.vue?macro=true";
import { default as InspectionTypesUVfZ27MurrMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/InspectionTypes.vue?macro=true";
import { default as Integrationsx9hthRIXC9Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Integrations.vue?macro=true";
import { default as Saas1FooterBKix3SUaizMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Saas1Footer.vue?macro=true";
import { default as Saas1HerowqdHVISQ18Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Saas1Hero.vue?macro=true";
import { default as TestimonialtBkTVvSzojMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Testimonial.vue?macro=true";
import { default as TestimonialCard9SA0c5bkDcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/TestimonialCard.vue?macro=true";
import { default as UseCasejX4OXd1jcuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/UseCase.vue?macro=true";
import { default as WorkkDKSFuN8hCMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Work.vue?macro=true";
import { default as data4afLuQQKdHMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/data.ts?macro=true";
import { default as indexdMkskkBaQcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/index.vue?macro=true";
import { default as typeVU3xkvqEfTMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/type.ts?macro=true";
import { default as indexMQh8xYEqJXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/[id]/index.vue?macro=true";
import { default as CarSearchBoxwhPgt2l02TMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/CarSearchBox.vue?macro=true";
import { default as ComparisonSliderur4vGUbp4MMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/ComparisonSlider.vue?macro=true";
import { default as CtarmKgCEXHfUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Cta.vue?macro=true";
import { default as FeatureshFY6LOjmARMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Features.vue?macro=true";
import { default as Features2EjVeTWmUjTMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Features2.vue?macro=true";
import { default as InspectionTypes9gf4JI8hudMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/InspectionTypes.vue?macro=true";
import { default as IntegrationsLjwRgHMDKGMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Integrations.vue?macro=true";
import { default as Saas1FooterKD7LnQqUK2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Saas1Footer.vue?macro=true";
import { default as Saas1Hero1JJL9QrwDGMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Saas1Hero.vue?macro=true";
import { default as TestimonialTbJQiiMguiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Testimonial.vue?macro=true";
import { default as TestimonialCardhGHdRFCVvwMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/TestimonialCard.vue?macro=true";
import { default as UseCasepQSduIkaCwMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/UseCase.vue?macro=true";
import { default as WorkNHAZgCqPTEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Work.vue?macro=true";
import { default as data07SEqRjC3jMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/data.ts?macro=true";
import { default as indexpuXAGcPuZXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/index.vue?macro=true";
import { default as typetImZkApN2QMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/type.ts?macro=true";
import { default as image44XyxsvEpPMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/assets/data/image.ts?macro=true";
import { default as BrandsasydPyvd7vMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Brands.vue?macro=true";
import { default as CtadBJmqWt16TMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Cta.vue?macro=true";
import { default as Faqs0fxfSFt1DCMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Faqs.vue?macro=true";
import { default as Features1wyuOl5XljEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Features1.vue?macro=true";
import { default as Features2LEUlk39OXlMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Features2.vue?macro=true";
import { default as MobileApp1FooterPaZ2PgCZSfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue?macro=true";
import { default as MobileApp1Hero37pR3Tg1avMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue?macro=true";
import { default as ProcessSwiperC8jj8jbHIoMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/ProcessSwiper.vue?macro=true";
import { default as TestimonialAkWm8bcQvqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Testimonial.vue?macro=true";
import { default as TestimonialCardNgYLJU4FLjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/TestimonialCard.vue?macro=true";
import { default as datacxemZ1xmY6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/data.ts?macro=true";
import { default as index6W3I67s9U3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/index.vue?macro=true";
import { default as AnchorNavigationwDseYyWOLTMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue?macro=true";
import { default as Breadcrumb6UXKv5aSIkMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue?macro=true";
import { default as CustomSyntaxXUSMhow3lVMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue?macro=true";
import { default as JarallaxbrG4TgcMfnMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue?macro=true";
import { default as LightGalleryUfORTN8mReMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue?macro=true";
import { default as LoadingSpinneryXCRXt2XMjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue?macro=true";
import { default as Parallaxysl9UdEqhiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue?macro=true";
import { default as PasswordInputJYtyFtt34IMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue?macro=true";
import { default as RellaxDD0xbLZC11Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue?macro=true";
import { default as VanillaTiltOeRprUK83yMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue?macro=true";
import { default as VideoPopupFKVP7RsGMYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue?macro=true";
import { default as ContactaPhggewb0jMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Contact.vue?macro=true";
import { default as Ctajn6TKnjEzUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Cta.vue?macro=true";
import { default as Featurefhodx2c0GKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Feature.vue?macro=true";
import { default as FeatureCardrtkLUuAKaNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/FeatureCard.vue?macro=true";
import { default as MedicalFootertFnH1bLVcKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/MedicalFooter.vue?macro=true";
import { default as MedicalHeroUqH5Bb2JhpMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/MedicalHero.vue?macro=true";
import { default as NewsQegkze6Vl5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/News.vue?macro=true";
import { default as NewsCard8ZL1j6MSc4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/NewsCard.vue?macro=true";
import { default as ServiceCardcdZ5dBUwUHMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/ServiceCard.vue?macro=true";
import { default as ServicesvGQbkaejZoMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Services.vue?macro=true";
import { default as TeamQia7Naa5ysMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Team.vue?macro=true";
import { default as TeamCardzujrHvary1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/TeamCard.vue?macro=true";
import { default as Testimonial4vIcCDLRhjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Testimonial.vue?macro=true";
import { default as TestimonialCardUr6WguaePGMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/TestimonialCard.vue?macro=true";
import { default as VideoReelWq7uN6Op56Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/VideoReel.vue?macro=true";
import { default as dataKJrwwZBk0MMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/data.ts?macro=true";
import { default as indexfLVrcpIDXUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/index.vue?macro=true";
import { default as typezKYfU0hwd9Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/type.ts?macro=true";
import { default as ArticleCardYOZB2xuo86Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/ArticleCard.vue?macro=true";
import { default as Articlesm8k1xh564aMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Articles.vue?macro=true";
import { default as CommentQ7ecNxEa37Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Comment.vue?macro=true";
import { default as CommentFormCiavfvlNM3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/CommentForm.vue?macro=true";
import { default as ContentucFz86jA9LMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Content.vue?macro=true";
import { default as JarallaxoUnu2qeHHqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Jarallax.vue?macro=true";
import { default as LightGalleryoIeZoF0blOMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/LightGallery.vue?macro=true";
import { default as SinglePostFooterpyZaeOw0o1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/SinglePostFooter.vue?macro=true";
import { default as SinglePostHeroPDpohIF5sKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/SinglePostHero.vue?macro=true";
import { default as dataKKjKoarGMSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/data.ts?macro=true";
import { default as indexOGJo0O8J1QMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/index.vue?macro=true";
import { default as type0icyoWtbYsMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/type.ts?macro=true";
import { default as BlogCardc0Brq5M7rMMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/BlogCard.vue?macro=true";
import { default as ContentpXUeX3oZT0Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/Content.vue?macro=true";
import { default as Footer26Zeh9nKY1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/Footer.vue?macro=true";
import { default as Pagination0a9pfXak91Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/Pagination.vue?macro=true";
import { default as datarlhljOwRtyMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/data.ts?macro=true";
import { default as index1bOKMHPxi9Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/index.vue?macro=true";
import { default as single_45newsBV2w6O5DbXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/single-news.vue?macro=true";
import { default as typemwk5bmaBpAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/type.ts?macro=true";
import { default as indexJplcLFA6IcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/partners/index.vue?macro=true";
import { default as indexRIeHDHC8WmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/payment/redirect/index.vue?macro=true";
import { default as ContentTZiZN8C7tFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/privacy-policy/components/Content.vue?macro=true";
import { default as SinglePostHerosL6ET8aAZKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/privacy-policy/components/SinglePostHero.vue?macro=true";
import { default as indexyktl2hnjHDMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/privacy-policy/index.vue?macro=true";
import { default as indexk3wqVjbVzyMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/sales-agent-form/index.vue?macro=true";
import { default as indexKataz2yIGcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/sales/index.vue?macro=true";
import { default as indexxSFJVDXKVoMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/success/index.vue?macro=true";
import { default as indexA9XwmUiBdvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/tamara/index.vue?macro=true";
import { default as Content3q6LB5aO59Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/terms-and-condition/components/Content.vue?macro=true";
import { default as indexykjMaxEAVLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/terms-and-condition/index.vue?macro=true";
import { default as index2PEWJ7TmqLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/trim/index.vue?macro=true";
import { default as indexNlDbLb6YT0Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranties/index.vue?macro=true";
import { default as indexuP8U8ZsLFvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty-form/index.vue?macro=true";
import { default as AppFeatureHcWzPAkIQPMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/AppFeature.vue?macro=true";
import { default as CarSearchBox1YQTU79SXbMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/CarSearchBox.vue?macro=true";
import { default as Pricingt9IiZOJvpiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Pricing.vue?macro=true";
import { default as PricingForMobilehFuSPKCcfxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/PricingForMobile.vue?macro=true";
import { default as Saas3Hero1bRly4dBepMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Saas3Hero.vue?macro=true";
import { default as TestimonialZ4GRC9BlsQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Testimonial.vue?macro=true";
import { default as TestimonialCard5yuzianWhEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/TestimonialCard.vue?macro=true";
import { default as WorkHQw6opPSNKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Work.vue?macro=true";
import { default as datafD3ov4XKAAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/data.ts?macro=true";
import { default as indexfkl1g81zKxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/index.vue?macro=true";
import { default as typeJJ0IusI8daMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/type.ts?macro=true";
export default [
  {
    name: _91_46_46_46slug_93cA7DtWz1ECMeta?.name ?? "slug",
    path: _91_46_46_46slug_93cA7DtWz1ECMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93cA7DtWz1ECMeta || {},
    alias: _91_46_46_46slug_93cA7DtWz1ECMeta?.alias || [],
    redirect: _91_46_46_46slug_93cA7DtWz1ECMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: About1Footer0vGrlS5duSMeta?.name ?? "about-components-About1Footer",
    path: About1Footer0vGrlS5duSMeta?.path ?? "/about/components/About1Footer",
    meta: About1Footer0vGrlS5duSMeta || {},
    alias: About1Footer0vGrlS5duSMeta?.alias || [],
    redirect: About1Footer0vGrlS5duSMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/About1Footer.vue").then(m => m.default || m)
  },
  {
    name: About1HeroAMOWO4loN5Meta?.name ?? "about-components-About1Hero",
    path: About1HeroAMOWO4loN5Meta?.path ?? "/about/components/About1Hero",
    meta: About1HeroAMOWO4loN5Meta || {},
    alias: About1HeroAMOWO4loN5Meta?.alias || [],
    redirect: About1HeroAMOWO4loN5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/About1Hero.vue").then(m => m.default || m)
  },
  {
    name: AwardsU7TH3Tbqu5Meta?.name ?? "about-components-Awards",
    path: AwardsU7TH3Tbqu5Meta?.path ?? "/about/components/Awards",
    meta: AwardsU7TH3Tbqu5Meta || {},
    alias: AwardsU7TH3Tbqu5Meta?.alias || [],
    redirect: AwardsU7TH3Tbqu5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Awards.vue").then(m => m.default || m)
  },
  {
    name: BenefitsmYgPGptQsdMeta?.name ?? "about-components-Benefits",
    path: BenefitsmYgPGptQsdMeta?.path ?? "/about/components/Benefits",
    meta: BenefitsmYgPGptQsdMeta || {},
    alias: BenefitsmYgPGptQsdMeta?.alias || [],
    redirect: BenefitsmYgPGptQsdMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Benefits.vue").then(m => m.default || m)
  },
  {
    name: Contactj6Xg02e4AFMeta?.name ?? "about-components-Contact",
    path: Contactj6Xg02e4AFMeta?.path ?? "/about/components/Contact",
    meta: Contactj6Xg02e4AFMeta || {},
    alias: Contactj6Xg02e4AFMeta?.alias || [],
    redirect: Contactj6Xg02e4AFMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: SocialNetworkIylYS5uyfBMeta?.name ?? "about-components-SocialNetwork",
    path: SocialNetworkIylYS5uyfBMeta?.path ?? "/about/components/SocialNetwork",
    meta: SocialNetworkIylYS5uyfBMeta || {},
    alias: SocialNetworkIylYS5uyfBMeta?.alias || [],
    redirect: SocialNetworkIylYS5uyfBMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/SocialNetwork.vue").then(m => m.default || m)
  },
  {
    name: Teamw51FN1wj6MMeta?.name ?? "about-components-Team",
    path: Teamw51FN1wj6MMeta?.path ?? "/about/components/Team",
    meta: Teamw51FN1wj6MMeta || {},
    alias: Teamw51FN1wj6MMeta?.alias || [],
    redirect: Teamw51FN1wj6MMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Team.vue").then(m => m.default || m)
  },
  {
    name: TeamCard4YFDKd2NCAMeta?.name ?? "about-components-TeamCard",
    path: TeamCard4YFDKd2NCAMeta?.path ?? "/about/components/TeamCard",
    meta: TeamCard4YFDKd2NCAMeta || {},
    alias: TeamCard4YFDKd2NCAMeta?.alias || [],
    redirect: TeamCard4YFDKd2NCAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/TeamCard.vue").then(m => m.default || m)
  },
  {
    name: Testimonialims6iPFAMiMeta?.name ?? "about-components-Testimonial",
    path: Testimonialims6iPFAMiMeta?.path ?? "/about/components/Testimonial",
    meta: Testimonialims6iPFAMiMeta || {},
    alias: Testimonialims6iPFAMiMeta?.alias || [],
    redirect: Testimonialims6iPFAMiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardgL6hu0ZRocMeta?.name ?? "about-components-TestimonialCard",
    path: TestimonialCardgL6hu0ZRocMeta?.path ?? "/about/components/TestimonialCard",
    meta: TestimonialCardgL6hu0ZRocMeta || {},
    alias: TestimonialCardgL6hu0ZRocMeta?.alias || [],
    redirect: TestimonialCardgL6hu0ZRocMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: VideoReelwirfelNckJMeta?.name ?? "about-components-VideoReel",
    path: VideoReelwirfelNckJMeta?.path ?? "/about/components/VideoReel",
    meta: VideoReelwirfelNckJMeta || {},
    alias: VideoReelwirfelNckJMeta?.alias || [],
    redirect: VideoReelwirfelNckJMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/components/VideoReel.vue").then(m => m.default || m)
  },
  {
    name: datal1N4IzYb65Meta?.name ?? "about-data",
    path: datal1N4IzYb65Meta?.path ?? "/about/data",
    meta: datal1N4IzYb65Meta || {},
    alias: datal1N4IzYb65Meta?.alias || [],
    redirect: datal1N4IzYb65Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/data.ts").then(m => m.default || m)
  },
  {
    name: indexASlNc1SJMNMeta?.name ?? "about",
    path: indexASlNc1SJMNMeta?.path ?? "/about",
    meta: indexASlNc1SJMNMeta || {},
    alias: indexASlNc1SJMNMeta?.alias || [],
    redirect: indexASlNc1SJMNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: typereCebe7Eo4Meta?.name ?? "about-type",
    path: typereCebe7Eo4Meta?.path ?? "/about/type",
    meta: typereCebe7Eo4Meta || {},
    alias: typereCebe7Eo4Meta?.alias || [],
    redirect: typereCebe7Eo4Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/about/type.ts").then(m => m.default || m)
  },
  {
    name: index6aBkD6KqF3Meta?.name ?? "branches",
    path: index6aBkD6KqF3Meta?.path ?? "/branches",
    meta: index6aBkD6KqF3Meta || {},
    alias: index6aBkD6KqF3Meta?.alias || [],
    redirect: index6aBkD6KqF3Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: index8UcgtWJcIhMeta?.name ?? "cancel",
    path: index8UcgtWJcIhMeta?.path ?? "/cancel",
    meta: index8UcgtWJcIhMeta || {},
    alias: index8UcgtWJcIhMeta?.alias || [],
    redirect: index8UcgtWJcIhMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: indexf7n8ycdseCMeta?.name ?? "career",
    path: indexf7n8ycdseCMeta?.path ?? "/career",
    meta: indexf7n8ycdseCMeta || {},
    alias: indexf7n8ycdseCMeta?.alias || [],
    redirect: indexf7n8ycdseCMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: FaqsYZxc3R3033Meta?.name ?? "cartrust-agent-components-Faqs",
    path: FaqsYZxc3R3033Meta?.path ?? "/cartrust-agent/components/Faqs",
    meta: FaqsYZxc3R3033Meta || {},
    alias: FaqsYZxc3R3033Meta?.alias || [],
    redirect: FaqsYZxc3R3033Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: FeaturesNX3YM8kk5QMeta?.name ?? "cartrust-agent-components-Features",
    path: FeaturesNX3YM8kk5QMeta?.path ?? "/cartrust-agent/components/Features",
    meta: FeaturesNX3YM8kk5QMeta || {},
    alias: FeaturesNX3YM8kk5QMeta?.alias || [],
    redirect: FeaturesNX3YM8kk5QMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Features.vue").then(m => m.default || m)
  },
  {
    name: Opportunities9gGpDw1txgMeta?.name ?? "cartrust-agent-components-Opportunities",
    path: Opportunities9gGpDw1txgMeta?.path ?? "/cartrust-agent/components/Opportunities",
    meta: Opportunities9gGpDw1txgMeta || {},
    alias: Opportunities9gGpDw1txgMeta?.alias || [],
    redirect: Opportunities9gGpDw1txgMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Opportunities.vue").then(m => m.default || m)
  },
  {
    name: PlanComparisonxJej4Uc22xMeta?.name ?? "cartrust-agent-components-PlanComparison",
    path: PlanComparisonxJej4Uc22xMeta?.path ?? "/cartrust-agent/components/PlanComparison",
    meta: PlanComparisonxJej4Uc22xMeta || {},
    alias: PlanComparisonxJej4Uc22xMeta?.alias || [],
    redirect: PlanComparisonxJej4Uc22xMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/PlanComparison.vue").then(m => m.default || m)
  },
  {
    name: PricinggUsvB5NWaIMeta?.name ?? "cartrust-agent-components-Pricing",
    path: PricinggUsvB5NWaIMeta?.path ?? "/cartrust-agent/components/Pricing",
    meta: PricinggUsvB5NWaIMeta || {},
    alias: PricinggUsvB5NWaIMeta?.alias || [],
    redirect: PricinggUsvB5NWaIMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: Saas3HeroYOk0ojpvt8Meta?.name ?? "cartrust-agent-components-Saas3Hero",
    path: Saas3HeroYOk0ojpvt8Meta?.path ?? "/cartrust-agent/components/Saas3Hero",
    meta: Saas3HeroYOk0ojpvt8Meta || {},
    alias: Saas3HeroYOk0ojpvt8Meta?.alias || [],
    redirect: Saas3HeroYOk0ojpvt8Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Saas3Hero.vue").then(m => m.default || m)
  },
  {
    name: Testimonialcu7WtkK2XQMeta?.name ?? "cartrust-agent-components-Testimonial",
    path: Testimonialcu7WtkK2XQMeta?.path ?? "/cartrust-agent/components/Testimonial",
    meta: Testimonialcu7WtkK2XQMeta || {},
    alias: Testimonialcu7WtkK2XQMeta?.alias || [],
    redirect: Testimonialcu7WtkK2XQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCard266mqlHK8rMeta?.name ?? "cartrust-agent-components-TestimonialCard",
    path: TestimonialCard266mqlHK8rMeta?.path ?? "/cartrust-agent/components/TestimonialCard",
    meta: TestimonialCard266mqlHK8rMeta || {},
    alias: TestimonialCard266mqlHK8rMeta?.alias || [],
    redirect: TestimonialCard266mqlHK8rMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: WorkcT8XwxMaWEMeta?.name ?? "cartrust-agent-components-Work",
    path: WorkcT8XwxMaWEMeta?.path ?? "/cartrust-agent/components/Work",
    meta: WorkcT8XwxMaWEMeta || {},
    alias: WorkcT8XwxMaWEMeta?.alias || [],
    redirect: WorkcT8XwxMaWEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/components/Work.vue").then(m => m.default || m)
  },
  {
    name: datayiTSWP83ZAMeta?.name ?? "cartrust-agent-data",
    path: datayiTSWP83ZAMeta?.path ?? "/cartrust-agent/data",
    meta: datayiTSWP83ZAMeta || {},
    alias: datayiTSWP83ZAMeta?.alias || [],
    redirect: datayiTSWP83ZAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/data.ts").then(m => m.default || m)
  },
  {
    name: indexcMQOENeUGbMeta?.name ?? "cartrust-agent",
    path: indexcMQOENeUGbMeta?.path ?? "/cartrust-agent",
    meta: indexcMQOENeUGbMeta || {},
    alias: indexcMQOENeUGbMeta?.alias || [],
    redirect: indexcMQOENeUGbMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/index.vue").then(m => m.default || m)
  },
  {
    name: typeY3oPGj7wm2Meta?.name ?? "cartrust-agent-type",
    path: typeY3oPGj7wm2Meta?.path ?? "/cartrust-agent/type",
    meta: typeY3oPGj7wm2Meta || {},
    alias: typeY3oPGj7wm2Meta?.alias || [],
    redirect: typeY3oPGj7wm2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/cartrust-agent/type.ts").then(m => m.default || m)
  },
  {
    name: Contacts1Footer1z8aaSTQUFMeta?.name ?? "contact-components-Contacts1Footer",
    path: Contacts1Footer1z8aaSTQUFMeta?.path ?? "/contact/components/Contacts1Footer",
    meta: Contacts1Footer1z8aaSTQUFMeta || {},
    alias: Contacts1Footer1z8aaSTQUFMeta?.alias || [],
    redirect: Contacts1Footer1z8aaSTQUFMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Contacts1Footer.vue").then(m => m.default || m)
  },
  {
    name: Contacts1HeroCxHRmspTVfMeta?.name ?? "contact-components-Contacts1Hero",
    path: Contacts1HeroCxHRmspTVfMeta?.path ?? "/contact/components/Contacts1Hero",
    meta: Contacts1HeroCxHRmspTVfMeta || {},
    alias: Contacts1HeroCxHRmspTVfMeta?.alias || [],
    redirect: Contacts1HeroCxHRmspTVfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Contacts1Hero.vue").then(m => m.default || m)
  },
  {
    name: Details0GPnd2bXd2Meta?.name ?? "contact-components-Details",
    path: Details0GPnd2bXd2Meta?.path ?? "/contact/components/Details",
    meta: Details0GPnd2bXd2Meta || {},
    alias: Details0GPnd2bXd2Meta?.alias || [],
    redirect: Details0GPnd2bXd2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Details.vue").then(m => m.default || m)
  },
  {
    name: Faqsw6DJNQFx5eMeta?.name ?? "contact-components-Faqs",
    path: Faqsw6DJNQFx5eMeta?.path ?? "/contact/components/Faqs",
    meta: Faqsw6DJNQFx5eMeta || {},
    alias: Faqsw6DJNQFx5eMeta?.alias || [],
    redirect: Faqsw6DJNQFx5eMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: dataIPcNwuhJMDMeta?.name ?? "contact-data",
    path: dataIPcNwuhJMDMeta?.path ?? "/contact/data",
    meta: dataIPcNwuhJMDMeta || {},
    alias: dataIPcNwuhJMDMeta?.alias || [],
    redirect: dataIPcNwuhJMDMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/data.ts").then(m => m.default || m)
  },
  {
    name: indexkcChzhQQQuMeta?.name ?? "contact",
    path: indexkcChzhQQQuMeta?.path ?? "/contact",
    meta: indexkcChzhQQQuMeta || {},
    alias: indexkcChzhQQQuMeta?.alias || [],
    redirect: indexkcChzhQQQuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexc1urR1DgoDMeta?.name ?? "dashboard",
    path: indexc1urR1DgoDMeta?.path ?? "/dashboard",
    meta: indexc1urR1DgoDMeta || {},
    alias: indexc1urR1DgoDMeta?.alias || [],
    redirect: indexc1urR1DgoDMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: inspectionskQ6flNxqLTMeta?.name ?? "dashboard-inspections",
    path: inspectionskQ6flNxqLTMeta?.path ?? "/dashboard/inspections",
    meta: inspectionskQ6flNxqLTMeta || {},
    alias: inspectionskQ6flNxqLTMeta?.alias || [],
    redirect: inspectionskQ6flNxqLTMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/inspections.vue").then(m => m.default || m)
  },
  {
    name: profile2vqbR5dXhsMeta?.name ?? "dashboard-profile",
    path: profile2vqbR5dXhsMeta?.path ?? "/dashboard/profile",
    meta: profile2vqbR5dXhsMeta || {},
    alias: profile2vqbR5dXhsMeta?.alias || [],
    redirect: profile2vqbR5dXhsMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: transaction77mUT5WhmlMeta?.name ?? "dashboard-transaction",
    path: transaction77mUT5WhmlMeta?.path ?? "/dashboard/transaction",
    meta: transaction77mUT5WhmlMeta || {},
    alias: transaction77mUT5WhmlMeta?.alias || [],
    redirect: transaction77mUT5WhmlMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/transaction.vue").then(m => m.default || m)
  },
  {
    name: vehicleskprnyGTuLKMeta?.name ?? "dashboard-vehicles",
    path: vehicleskprnyGTuLKMeta?.path ?? "/dashboard/vehicles",
    meta: vehicleskprnyGTuLKMeta || {},
    alias: vehicleskprnyGTuLKMeta?.alias || [],
    redirect: vehicleskprnyGTuLKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/vehicles.vue").then(m => m.default || m)
  },
  {
    name: warrantiesMfIBXHtEpcMeta?.name ?? "dashboard-warranties",
    path: warrantiesMfIBXHtEpcMeta?.path ?? "/dashboard/warranties",
    meta: warrantiesMfIBXHtEpcMeta || {},
    alias: warrantiesMfIBXHtEpcMeta?.alias || [],
    redirect: warrantiesMfIBXHtEpcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/dashboard/warranties.vue").then(m => m.default || m)
  },
  {
    name: ContentdozudMB1KoMeta?.name ?? "data-products-disclaimer-components-Content",
    path: ContentdozudMB1KoMeta?.path ?? "/data-products-disclaimer/components/Content",
    meta: ContentdozudMB1KoMeta || {},
    alias: ContentdozudMB1KoMeta?.alias || [],
    redirect: ContentdozudMB1KoMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/data-products-disclaimer/components/Content.vue").then(m => m.default || m)
  },
  {
    name: indexfzaXNRQUImMeta?.name ?? "data-products-disclaimer",
    path: indexfzaXNRQUImMeta?.path ?? "/data-products-disclaimer",
    meta: indexfzaXNRQUImMeta || {},
    alias: indexfzaXNRQUImMeta?.alias || [],
    redirect: indexfzaXNRQUImMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/data-products-disclaimer/index.vue").then(m => m.default || m)
  },
  {
    name: BrandsdLFb1QMJTUMeta?.name ?? "evaluation-components-Brands",
    path: BrandsdLFb1QMJTUMeta?.path ?? "/evaluation/components/Brands",
    meta: BrandsdLFb1QMJTUMeta || {},
    alias: BrandsdLFb1QMJTUMeta?.alias || [],
    redirect: BrandsdLFb1QMJTUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBoxjGY2mtpBVdMeta?.name ?? "evaluation-components-CarSearchBox",
    path: CarSearchBoxjGY2mtpBVdMeta?.path ?? "/evaluation/components/CarSearchBox",
    meta: CarSearchBoxjGY2mtpBVdMeta || {},
    alias: CarSearchBoxjGY2mtpBVdMeta?.alias || [],
    redirect: CarSearchBoxjGY2mtpBVdMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: ContactUVacVwSaMiMeta?.name ?? "evaluation-components-Contact",
    path: ContactUVacVwSaMiMeta?.path ?? "/evaluation/components/Contact",
    meta: ContactUVacVwSaMiMeta || {},
    alias: ContactUVacVwSaMiMeta?.alias || [],
    redirect: ContactUVacVwSaMiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: CovidBannerwt9gGes4RqMeta?.name ?? "evaluation-components-CovidBanner",
    path: CovidBannerwt9gGes4RqMeta?.path ?? "/evaluation/components/CovidBanner",
    meta: CovidBannerwt9gGes4RqMeta || {},
    alias: CovidBannerwt9gGes4RqMeta?.alias || [],
    redirect: CovidBannerwt9gGes4RqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/CovidBanner.vue").then(m => m.default || m)
  },
  {
    name: FeatureMtnT5QvPW2Meta?.name ?? "evaluation-components-Feature",
    path: FeatureMtnT5QvPW2Meta?.path ?? "/evaluation/components/Feature",
    meta: FeatureMtnT5QvPW2Meta || {},
    alias: FeatureMtnT5QvPW2Meta?.alias || [],
    redirect: FeatureMtnT5QvPW2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Feature.vue").then(m => m.default || m)
  },
  {
    name: FinancialFooter08vu2Z5eAgMeta?.name ?? "evaluation-components-FinancialFooter",
    path: FinancialFooter08vu2Z5eAgMeta?.path ?? "/evaluation/components/FinancialFooter",
    meta: FinancialFooter08vu2Z5eAgMeta || {},
    alias: FinancialFooter08vu2Z5eAgMeta?.alias || [],
    redirect: FinancialFooter08vu2Z5eAgMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/FinancialFooter.vue").then(m => m.default || m)
  },
  {
    name: FinancialHeroVoo3gg0eboMeta?.name ?? "evaluation-components-FinancialHero",
    path: FinancialHeroVoo3gg0eboMeta?.path ?? "/evaluation/components/FinancialHero",
    meta: FinancialHeroVoo3gg0eboMeta || {},
    alias: FinancialHeroVoo3gg0eboMeta?.alias || [],
    redirect: FinancialHeroVoo3gg0eboMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/FinancialHero.vue").then(m => m.default || m)
  },
  {
    name: IndustrieSwiper9gXHOIhlJlMeta?.name ?? "evaluation-components-IndustrieSwiper",
    path: IndustrieSwiper9gXHOIhlJlMeta?.path ?? "/evaluation/components/IndustrieSwiper",
    meta: IndustrieSwiper9gXHOIhlJlMeta || {},
    alias: IndustrieSwiper9gXHOIhlJlMeta?.alias || [],
    redirect: IndustrieSwiper9gXHOIhlJlMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/IndustrieSwiper.vue").then(m => m.default || m)
  },
  {
    name: NewsCarddO9cAXseA0Meta?.name ?? "evaluation-components-NewsCard",
    path: NewsCarddO9cAXseA0Meta?.path ?? "/evaluation/components/NewsCard",
    meta: NewsCarddO9cAXseA0Meta || {},
    alias: NewsCarddO9cAXseA0Meta?.alias || [],
    redirect: NewsCarddO9cAXseA0Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/NewsCard.vue").then(m => m.default || m)
  },
  {
    name: NewsSwiperJGq5FoT0fUMeta?.name ?? "evaluation-components-NewsSwiper",
    path: NewsSwiperJGq5FoT0fUMeta?.path ?? "/evaluation/components/NewsSwiper",
    meta: NewsSwiperJGq5FoT0fUMeta || {},
    alias: NewsSwiperJGq5FoT0fUMeta?.alias || [],
    redirect: NewsSwiperJGq5FoT0fUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/NewsSwiper.vue").then(m => m.default || m)
  },
  {
    name: PricingyocKp4jSCYMeta?.name ?? "evaluation-components-Pricing",
    path: PricingyocKp4jSCYMeta?.path ?? "/evaluation/components/Pricing",
    meta: PricingyocKp4jSCYMeta || {},
    alias: PricingyocKp4jSCYMeta?.alias || [],
    redirect: PricingyocKp4jSCYMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: Purchase4UK0eHBxlrMeta?.name ?? "evaluation-components-Purchase",
    path: Purchase4UK0eHBxlrMeta?.path ?? "/evaluation/components/Purchase",
    meta: Purchase4UK0eHBxlrMeta || {},
    alias: Purchase4UK0eHBxlrMeta?.alias || [],
    redirect: Purchase4UK0eHBxlrMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Purchase.vue").then(m => m.default || m)
  },
  {
    name: ServicesWEq8TbDnLuMeta?.name ?? "evaluation-components-Services",
    path: ServicesWEq8TbDnLuMeta?.path ?? "/evaluation/components/Services",
    meta: ServicesWEq8TbDnLuMeta || {},
    alias: ServicesWEq8TbDnLuMeta?.alias || [],
    redirect: ServicesWEq8TbDnLuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Services.vue").then(m => m.default || m)
  },
  {
    name: Sponsorsyh4APAtX1iMeta?.name ?? "evaluation-components-Sponsors",
    path: Sponsorsyh4APAtX1iMeta?.path ?? "/evaluation/components/Sponsors",
    meta: Sponsorsyh4APAtX1iMeta || {},
    alias: Sponsorsyh4APAtX1iMeta?.alias || [],
    redirect: Sponsorsyh4APAtX1iMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Sponsors.vue").then(m => m.default || m)
  },
  {
    name: TestimonialKMjm1aXpcGMeta?.name ?? "evaluation-components-Testimonial",
    path: TestimonialKMjm1aXpcGMeta?.path ?? "/evaluation/components/Testimonial",
    meta: TestimonialKMjm1aXpcGMeta || {},
    alias: TestimonialKMjm1aXpcGMeta?.alias || [],
    redirect: TestimonialKMjm1aXpcGMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCard3HKLL1VFHyMeta?.name ?? "evaluation-components-TestimonialCard",
    path: TestimonialCard3HKLL1VFHyMeta?.path ?? "/evaluation/components/TestimonialCard",
    meta: TestimonialCard3HKLL1VFHyMeta || {},
    alias: TestimonialCard3HKLL1VFHyMeta?.alias || [],
    redirect: TestimonialCard3HKLL1VFHyMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: data1X8YHCyczeMeta?.name ?? "evaluation-data",
    path: data1X8YHCyczeMeta?.path ?? "/evaluation/data",
    meta: data1X8YHCyczeMeta || {},
    alias: data1X8YHCyczeMeta?.alias || [],
    redirect: data1X8YHCyczeMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/data.ts").then(m => m.default || m)
  },
  {
    name: indexI3mMHoxBgLMeta?.name ?? "evaluation",
    path: indexI3mMHoxBgLMeta?.path ?? "/evaluation",
    meta: indexI3mMHoxBgLMeta || {},
    alias: indexI3mMHoxBgLMeta?.alias || [],
    redirect: indexI3mMHoxBgLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: evaluation_45types9X3Esx580MMeta?.name ?? "evaluation-types-evaluation-types",
    path: evaluation_45types9X3Esx580MMeta?.path ?? "/evaluation/types/evaluation-types",
    meta: evaluation_45types9X3Esx580MMeta || {},
    alias: evaluation_45types9X3Esx580MMeta?.alias || [],
    redirect: evaluation_45types9X3Esx580MMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluation/types/evaluation-types.ts").then(m => m.default || m)
  },
  {
    name: indexh6klPKCAZZMeta?.name ?? "evaluations-id",
    path: indexh6klPKCAZZMeta?.path ?? "/evaluations/:id()",
    meta: indexh6klPKCAZZMeta || {},
    alias: indexh6klPKCAZZMeta?.alias || [],
    redirect: indexh6klPKCAZZMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: ReEvaluationHTiQKLaolhMeta?.name ?? "evaluations-Components-ReEvaluation",
    path: ReEvaluationHTiQKLaolhMeta?.path ?? "/evaluations/Components/ReEvaluation",
    meta: ReEvaluationHTiQKLaolhMeta || {},
    alias: ReEvaluationHTiQKLaolhMeta?.alias || [],
    redirect: ReEvaluationHTiQKLaolhMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluations/Components/ReEvaluation.vue").then(m => m.default || m)
  },
  {
    name: indexe3TU6yzPOZMeta?.name ?? "evaluations",
    path: indexe3TU6yzPOZMeta?.path ?? "/evaluations",
    meta: indexe3TU6yzPOZMeta || {},
    alias: indexe3TU6yzPOZMeta?.alias || [],
    redirect: indexe3TU6yzPOZMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/evaluations/index.vue").then(m => m.default || m)
  },
  {
    name: indexh37sdD49hPMeta?.name ?? "fail",
    path: indexh37sdD49hPMeta?.path ?? "/fail",
    meta: indexh37sdD49hPMeta || {},
    alias: indexh37sdD49hPMeta?.alias || [],
    redirect: indexh37sdD49hPMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/fail/index.vue").then(m => m.default || m)
  },
  {
    name: Contacts1FooterZi3Htr3CjzMeta?.name ?? "faq-components-Contacts1Footer",
    path: Contacts1FooterZi3Htr3CjzMeta?.path ?? "/faq/components/Contacts1Footer",
    meta: Contacts1FooterZi3Htr3CjzMeta || {},
    alias: Contacts1FooterZi3Htr3CjzMeta?.alias || [],
    redirect: Contacts1FooterZi3Htr3CjzMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Contacts1Footer.vue").then(m => m.default || m)
  },
  {
    name: Contacts1HeroQzUig3iyN0Meta?.name ?? "faq-components-Contacts1Hero",
    path: Contacts1HeroQzUig3iyN0Meta?.path ?? "/faq/components/Contacts1Hero",
    meta: Contacts1HeroQzUig3iyN0Meta || {},
    alias: Contacts1HeroQzUig3iyN0Meta?.alias || [],
    redirect: Contacts1HeroQzUig3iyN0Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Contacts1Hero.vue").then(m => m.default || m)
  },
  {
    name: DetailshJUQ6F3cSbMeta?.name ?? "faq-components-Details",
    path: DetailshJUQ6F3cSbMeta?.path ?? "/faq/components/Details",
    meta: DetailshJUQ6F3cSbMeta || {},
    alias: DetailshJUQ6F3cSbMeta?.alias || [],
    redirect: DetailshJUQ6F3cSbMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Details.vue").then(m => m.default || m)
  },
  {
    name: FaqsEaSzCmNWdQMeta?.name ?? "faq-components-Faqs",
    path: FaqsEaSzCmNWdQMeta?.path ?? "/faq/components/Faqs",
    meta: FaqsEaSzCmNWdQMeta || {},
    alias: FaqsEaSzCmNWdQMeta?.alias || [],
    redirect: FaqsEaSzCmNWdQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: dataau2DyfsZzuMeta?.name ?? "faq-data",
    path: dataau2DyfsZzuMeta?.path ?? "/faq/data",
    meta: dataau2DyfsZzuMeta || {},
    alias: dataau2DyfsZzuMeta?.alias || [],
    redirect: dataau2DyfsZzuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/data.ts").then(m => m.default || m)
  },
  {
    name: indexRJNTBBhPt9Meta?.name ?? "faq",
    path: indexRJNTBBhPt9Meta?.path ?? "/faq",
    meta: indexRJNTBBhPt9Meta || {},
    alias: indexRJNTBBhPt9Meta?.alias || [],
    redirect: indexRJNTBBhPt9Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: HeroSectionUJCPn94wXtMeta?.name ?? "help-me-by-a-car-components-HeroSection",
    path: HeroSectionUJCPn94wXtMeta?.path ?? "/help-me-by-a-car/components/HeroSection",
    meta: HeroSectionUJCPn94wXtMeta || {},
    alias: HeroSectionUJCPn94wXtMeta?.alias || [],
    redirect: HeroSectionUJCPn94wXtMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: SearchCarENqdh1QUOfMeta?.name ?? "help-me-by-a-car-components-SearchCar",
    path: SearchCarENqdh1QUOfMeta?.path ?? "/help-me-by-a-car/components/SearchCar",
    meta: SearchCarENqdh1QUOfMeta || {},
    alias: SearchCarENqdh1QUOfMeta?.alias || [],
    redirect: SearchCarENqdh1QUOfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/components/SearchCar.vue").then(m => m.default || m)
  },
  {
    name: SpecificationsJI1h1xiWpsMeta?.name ?? "help-me-by-a-car-components-Specifications",
    path: SpecificationsJI1h1xiWpsMeta?.path ?? "/help-me-by-a-car/components/Specifications",
    meta: SpecificationsJI1h1xiWpsMeta || {},
    alias: SpecificationsJI1h1xiWpsMeta?.alias || [],
    redirect: SpecificationsJI1h1xiWpsMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/components/Specifications.vue").then(m => m.default || m)
  },
  {
    name: indexvnC6fLyI8bMeta?.name ?? "help-me-by-a-car",
    path: indexvnC6fLyI8bMeta?.path ?? "/help-me-by-a-car",
    meta: indexvnC6fLyI8bMeta || {},
    alias: indexvnC6fLyI8bMeta?.alias || [],
    redirect: indexvnC6fLyI8bMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/help-me-by-a-car/index.vue").then(m => m.default || m)
  },
  {
    name: ContentmbLDiirza4Meta?.name ?? "impartiality-policy-components-Content",
    path: ContentmbLDiirza4Meta?.path ?? "/impartiality-policy/components/Content",
    meta: ContentmbLDiirza4Meta || {},
    alias: ContentmbLDiirza4Meta?.alias || [],
    redirect: ContentmbLDiirza4Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/impartiality-policy/components/Content.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHerodk0V3hx8aqMeta?.name ?? "impartiality-policy-components-SinglePostHero",
    path: SinglePostHerodk0V3hx8aqMeta?.path ?? "/impartiality-policy/components/SinglePostHero",
    meta: SinglePostHerodk0V3hx8aqMeta || {},
    alias: SinglePostHerodk0V3hx8aqMeta?.alias || [],
    redirect: SinglePostHerodk0V3hx8aqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/impartiality-policy/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: indexw4bVjIkWyCMeta?.name ?? "impartiality-policy",
    path: indexw4bVjIkWyCMeta?.path ?? "/impartiality-policy",
    meta: indexw4bVjIkWyCMeta || {},
    alias: indexw4bVjIkWyCMeta?.alias || [],
    redirect: indexw4bVjIkWyCMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/impartiality-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexu3HhzNdD9oMeta?.name ?? "index",
    path: indexu3HhzNdD9oMeta?.path ?? "/",
    meta: indexu3HhzNdD9oMeta || {},
    alias: indexu3HhzNdD9oMeta?.alias || [],
    redirect: indexu3HhzNdD9oMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBoxQ24bUEPgIWMeta?.name ?? "inspection-components-CarSearchBox",
    path: CarSearchBoxQ24bUEPgIWMeta?.path ?? "/inspection/components/CarSearchBox",
    meta: CarSearchBoxQ24bUEPgIWMeta || {},
    alias: CarSearchBoxQ24bUEPgIWMeta?.alias || [],
    redirect: CarSearchBoxQ24bUEPgIWMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: ComparisonSliderzezCakPLKAMeta?.name ?? "inspection-components-ComparisonSlider",
    path: ComparisonSliderzezCakPLKAMeta?.path ?? "/inspection/components/ComparisonSlider",
    meta: ComparisonSliderzezCakPLKAMeta || {},
    alias: ComparisonSliderzezCakPLKAMeta?.alias || [],
    redirect: ComparisonSliderzezCakPLKAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/ComparisonSlider.vue").then(m => m.default || m)
  },
  {
    name: CtaM5i2Z27tRbMeta?.name ?? "inspection-components-Cta",
    path: CtaM5i2Z27tRbMeta?.path ?? "/inspection/components/Cta",
    meta: CtaM5i2Z27tRbMeta || {},
    alias: CtaM5i2Z27tRbMeta?.alias || [],
    redirect: CtaM5i2Z27tRbMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: FeaturesgGhf98ONVyMeta?.name ?? "inspection-components-Features",
    path: FeaturesgGhf98ONVyMeta?.path ?? "/inspection/components/Features",
    meta: FeaturesgGhf98ONVyMeta || {},
    alias: FeaturesgGhf98ONVyMeta?.alias || [],
    redirect: FeaturesgGhf98ONVyMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Features.vue").then(m => m.default || m)
  },
  {
    name: Features2FrZj3vupN5Meta?.name ?? "inspection-components-Features2",
    path: Features2FrZj3vupN5Meta?.path ?? "/inspection/components/Features2",
    meta: Features2FrZj3vupN5Meta || {},
    alias: Features2FrZj3vupN5Meta?.alias || [],
    redirect: Features2FrZj3vupN5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Features2.vue").then(m => m.default || m)
  },
  {
    name: InspectionTypesUVfZ27MurrMeta?.name ?? "inspection-components-InspectionTypes",
    path: InspectionTypesUVfZ27MurrMeta?.path ?? "/inspection/components/InspectionTypes",
    meta: InspectionTypesUVfZ27MurrMeta || {},
    alias: InspectionTypesUVfZ27MurrMeta?.alias || [],
    redirect: InspectionTypesUVfZ27MurrMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/InspectionTypes.vue").then(m => m.default || m)
  },
  {
    name: Integrationsx9hthRIXC9Meta?.name ?? "inspection-components-Integrations",
    path: Integrationsx9hthRIXC9Meta?.path ?? "/inspection/components/Integrations",
    meta: Integrationsx9hthRIXC9Meta || {},
    alias: Integrationsx9hthRIXC9Meta?.alias || [],
    redirect: Integrationsx9hthRIXC9Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Integrations.vue").then(m => m.default || m)
  },
  {
    name: Saas1FooterBKix3SUaizMeta?.name ?? "inspection-components-Saas1Footer",
    path: Saas1FooterBKix3SUaizMeta?.path ?? "/inspection/components/Saas1Footer",
    meta: Saas1FooterBKix3SUaizMeta || {},
    alias: Saas1FooterBKix3SUaizMeta?.alias || [],
    redirect: Saas1FooterBKix3SUaizMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Saas1Footer.vue").then(m => m.default || m)
  },
  {
    name: Saas1HerowqdHVISQ18Meta?.name ?? "inspection-components-Saas1Hero",
    path: Saas1HerowqdHVISQ18Meta?.path ?? "/inspection/components/Saas1Hero",
    meta: Saas1HerowqdHVISQ18Meta || {},
    alias: Saas1HerowqdHVISQ18Meta?.alias || [],
    redirect: Saas1HerowqdHVISQ18Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Saas1Hero.vue").then(m => m.default || m)
  },
  {
    name: TestimonialtBkTVvSzojMeta?.name ?? "inspection-components-Testimonial",
    path: TestimonialtBkTVvSzojMeta?.path ?? "/inspection/components/Testimonial",
    meta: TestimonialtBkTVvSzojMeta || {},
    alias: TestimonialtBkTVvSzojMeta?.alias || [],
    redirect: TestimonialtBkTVvSzojMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCard9SA0c5bkDcMeta?.name ?? "inspection-components-TestimonialCard",
    path: TestimonialCard9SA0c5bkDcMeta?.path ?? "/inspection/components/TestimonialCard",
    meta: TestimonialCard9SA0c5bkDcMeta || {},
    alias: TestimonialCard9SA0c5bkDcMeta?.alias || [],
    redirect: TestimonialCard9SA0c5bkDcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: UseCasejX4OXd1jcuMeta?.name ?? "inspection-components-UseCase",
    path: UseCasejX4OXd1jcuMeta?.path ?? "/inspection/components/UseCase",
    meta: UseCasejX4OXd1jcuMeta || {},
    alias: UseCasejX4OXd1jcuMeta?.alias || [],
    redirect: UseCasejX4OXd1jcuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/UseCase.vue").then(m => m.default || m)
  },
  {
    name: WorkkDKSFuN8hCMeta?.name ?? "inspection-components-Work",
    path: WorkkDKSFuN8hCMeta?.path ?? "/inspection/components/Work",
    meta: WorkkDKSFuN8hCMeta || {},
    alias: WorkkDKSFuN8hCMeta?.alias || [],
    redirect: WorkkDKSFuN8hCMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/components/Work.vue").then(m => m.default || m)
  },
  {
    name: data4afLuQQKdHMeta?.name ?? "inspection-data",
    path: data4afLuQQKdHMeta?.path ?? "/inspection/data",
    meta: data4afLuQQKdHMeta || {},
    alias: data4afLuQQKdHMeta?.alias || [],
    redirect: data4afLuQQKdHMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/data.ts").then(m => m.default || m)
  },
  {
    name: indexdMkskkBaQcMeta?.name ?? "inspection",
    path: indexdMkskkBaQcMeta?.path ?? "/inspection",
    meta: indexdMkskkBaQcMeta || {},
    alias: indexdMkskkBaQcMeta?.alias || [],
    redirect: indexdMkskkBaQcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/index.vue").then(m => m.default || m)
  },
  {
    name: typeVU3xkvqEfTMeta?.name ?? "inspection-type",
    path: typeVU3xkvqEfTMeta?.path ?? "/inspection/type",
    meta: typeVU3xkvqEfTMeta || {},
    alias: typeVU3xkvqEfTMeta?.alias || [],
    redirect: typeVU3xkvqEfTMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspection/type.ts").then(m => m.default || m)
  },
  {
    name: indexMQh8xYEqJXMeta?.name ?? "inspections-id",
    path: indexMQh8xYEqJXMeta?.path ?? "/inspections/:id()",
    meta: indexMQh8xYEqJXMeta || {},
    alias: indexMQh8xYEqJXMeta?.alias || [],
    redirect: indexMQh8xYEqJXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBoxwhPgt2l02TMeta?.name ?? "inspections-components-CarSearchBox",
    path: CarSearchBoxwhPgt2l02TMeta?.path ?? "/inspections/components/CarSearchBox",
    meta: CarSearchBoxwhPgt2l02TMeta || {},
    alias: CarSearchBoxwhPgt2l02TMeta?.alias || [],
    redirect: CarSearchBoxwhPgt2l02TMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: ComparisonSliderur4vGUbp4MMeta?.name ?? "inspections-components-ComparisonSlider",
    path: ComparisonSliderur4vGUbp4MMeta?.path ?? "/inspections/components/ComparisonSlider",
    meta: ComparisonSliderur4vGUbp4MMeta || {},
    alias: ComparisonSliderur4vGUbp4MMeta?.alias || [],
    redirect: ComparisonSliderur4vGUbp4MMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/ComparisonSlider.vue").then(m => m.default || m)
  },
  {
    name: CtarmKgCEXHfUMeta?.name ?? "inspections-components-Cta",
    path: CtarmKgCEXHfUMeta?.path ?? "/inspections/components/Cta",
    meta: CtarmKgCEXHfUMeta || {},
    alias: CtarmKgCEXHfUMeta?.alias || [],
    redirect: CtarmKgCEXHfUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: FeatureshFY6LOjmARMeta?.name ?? "inspections-components-Features",
    path: FeatureshFY6LOjmARMeta?.path ?? "/inspections/components/Features",
    meta: FeatureshFY6LOjmARMeta || {},
    alias: FeatureshFY6LOjmARMeta?.alias || [],
    redirect: FeatureshFY6LOjmARMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Features.vue").then(m => m.default || m)
  },
  {
    name: Features2EjVeTWmUjTMeta?.name ?? "inspections-components-Features2",
    path: Features2EjVeTWmUjTMeta?.path ?? "/inspections/components/Features2",
    meta: Features2EjVeTWmUjTMeta || {},
    alias: Features2EjVeTWmUjTMeta?.alias || [],
    redirect: Features2EjVeTWmUjTMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Features2.vue").then(m => m.default || m)
  },
  {
    name: InspectionTypes9gf4JI8hudMeta?.name ?? "inspections-components-InspectionTypes",
    path: InspectionTypes9gf4JI8hudMeta?.path ?? "/inspections/components/InspectionTypes",
    meta: InspectionTypes9gf4JI8hudMeta || {},
    alias: InspectionTypes9gf4JI8hudMeta?.alias || [],
    redirect: InspectionTypes9gf4JI8hudMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/InspectionTypes.vue").then(m => m.default || m)
  },
  {
    name: IntegrationsLjwRgHMDKGMeta?.name ?? "inspections-components-Integrations",
    path: IntegrationsLjwRgHMDKGMeta?.path ?? "/inspections/components/Integrations",
    meta: IntegrationsLjwRgHMDKGMeta || {},
    alias: IntegrationsLjwRgHMDKGMeta?.alias || [],
    redirect: IntegrationsLjwRgHMDKGMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Integrations.vue").then(m => m.default || m)
  },
  {
    name: Saas1FooterKD7LnQqUK2Meta?.name ?? "inspections-components-Saas1Footer",
    path: Saas1FooterKD7LnQqUK2Meta?.path ?? "/inspections/components/Saas1Footer",
    meta: Saas1FooterKD7LnQqUK2Meta || {},
    alias: Saas1FooterKD7LnQqUK2Meta?.alias || [],
    redirect: Saas1FooterKD7LnQqUK2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Saas1Footer.vue").then(m => m.default || m)
  },
  {
    name: Saas1Hero1JJL9QrwDGMeta?.name ?? "inspections-components-Saas1Hero",
    path: Saas1Hero1JJL9QrwDGMeta?.path ?? "/inspections/components/Saas1Hero",
    meta: Saas1Hero1JJL9QrwDGMeta || {},
    alias: Saas1Hero1JJL9QrwDGMeta?.alias || [],
    redirect: Saas1Hero1JJL9QrwDGMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Saas1Hero.vue").then(m => m.default || m)
  },
  {
    name: TestimonialTbJQiiMguiMeta?.name ?? "inspections-components-Testimonial",
    path: TestimonialTbJQiiMguiMeta?.path ?? "/inspections/components/Testimonial",
    meta: TestimonialTbJQiiMguiMeta || {},
    alias: TestimonialTbJQiiMguiMeta?.alias || [],
    redirect: TestimonialTbJQiiMguiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardhGHdRFCVvwMeta?.name ?? "inspections-components-TestimonialCard",
    path: TestimonialCardhGHdRFCVvwMeta?.path ?? "/inspections/components/TestimonialCard",
    meta: TestimonialCardhGHdRFCVvwMeta || {},
    alias: TestimonialCardhGHdRFCVvwMeta?.alias || [],
    redirect: TestimonialCardhGHdRFCVvwMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: UseCasepQSduIkaCwMeta?.name ?? "inspections-components-UseCase",
    path: UseCasepQSduIkaCwMeta?.path ?? "/inspections/components/UseCase",
    meta: UseCasepQSduIkaCwMeta || {},
    alias: UseCasepQSduIkaCwMeta?.alias || [],
    redirect: UseCasepQSduIkaCwMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/UseCase.vue").then(m => m.default || m)
  },
  {
    name: WorkNHAZgCqPTEMeta?.name ?? "inspections-components-Work",
    path: WorkNHAZgCqPTEMeta?.path ?? "/inspections/components/Work",
    meta: WorkNHAZgCqPTEMeta || {},
    alias: WorkNHAZgCqPTEMeta?.alias || [],
    redirect: WorkNHAZgCqPTEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/components/Work.vue").then(m => m.default || m)
  },
  {
    name: data07SEqRjC3jMeta?.name ?? "inspections-data",
    path: data07SEqRjC3jMeta?.path ?? "/inspections/data",
    meta: data07SEqRjC3jMeta || {},
    alias: data07SEqRjC3jMeta?.alias || [],
    redirect: data07SEqRjC3jMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/data.ts").then(m => m.default || m)
  },
  {
    name: indexpuXAGcPuZXMeta?.name ?? "inspections",
    path: indexpuXAGcPuZXMeta?.path ?? "/inspections",
    meta: indexpuXAGcPuZXMeta || {},
    alias: indexpuXAGcPuZXMeta?.alias || [],
    redirect: indexpuXAGcPuZXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/index.vue").then(m => m.default || m)
  },
  {
    name: typetImZkApN2QMeta?.name ?? "inspections-type",
    path: typetImZkApN2QMeta?.path ?? "/inspections/type",
    meta: typetImZkApN2QMeta || {},
    alias: typetImZkApN2QMeta?.alias || [],
    redirect: typetImZkApN2QMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/inspections/type.ts").then(m => m.default || m)
  },
  {
    name: image44XyxsvEpPMeta?.name ?? "landing-mobile-app-1-assets-data-image",
    path: image44XyxsvEpPMeta?.path ?? "/landing/mobile-app-1/assets/data/image",
    meta: image44XyxsvEpPMeta || {},
    alias: image44XyxsvEpPMeta?.alias || [],
    redirect: image44XyxsvEpPMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/assets/data/image.ts").then(m => m.default || m)
  },
  {
    name: BrandsasydPyvd7vMeta?.name ?? "landing-mobile-app-1-components-Brands",
    path: BrandsasydPyvd7vMeta?.path ?? "/landing/mobile-app-1/components/Brands",
    meta: BrandsasydPyvd7vMeta || {},
    alias: BrandsasydPyvd7vMeta?.alias || [],
    redirect: BrandsasydPyvd7vMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: CtadBJmqWt16TMeta?.name ?? "landing-mobile-app-1-components-Cta",
    path: CtadBJmqWt16TMeta?.path ?? "/landing/mobile-app-1/components/Cta",
    meta: CtadBJmqWt16TMeta || {},
    alias: CtadBJmqWt16TMeta?.alias || [],
    redirect: CtadBJmqWt16TMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: Faqs0fxfSFt1DCMeta?.name ?? "landing-mobile-app-1-components-Faqs",
    path: Faqs0fxfSFt1DCMeta?.path ?? "/landing/mobile-app-1/components/Faqs",
    meta: Faqs0fxfSFt1DCMeta || {},
    alias: Faqs0fxfSFt1DCMeta?.alias || [],
    redirect: Faqs0fxfSFt1DCMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: Features1wyuOl5XljEMeta?.name ?? "landing-mobile-app-1-components-Features1",
    path: Features1wyuOl5XljEMeta?.path ?? "/landing/mobile-app-1/components/Features1",
    meta: Features1wyuOl5XljEMeta || {},
    alias: Features1wyuOl5XljEMeta?.alias || [],
    redirect: Features1wyuOl5XljEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Features1.vue").then(m => m.default || m)
  },
  {
    name: Features2LEUlk39OXlMeta?.name ?? "landing-mobile-app-1-components-Features2",
    path: Features2LEUlk39OXlMeta?.path ?? "/landing/mobile-app-1/components/Features2",
    meta: Features2LEUlk39OXlMeta || {},
    alias: Features2LEUlk39OXlMeta?.alias || [],
    redirect: Features2LEUlk39OXlMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Features2.vue").then(m => m.default || m)
  },
  {
    name: MobileApp1FooterPaZ2PgCZSfMeta?.name ?? "landing-mobile-app-1-components-MobileApp1Footer",
    path: MobileApp1FooterPaZ2PgCZSfMeta?.path ?? "/landing/mobile-app-1/components/MobileApp1Footer",
    meta: MobileApp1FooterPaZ2PgCZSfMeta || {},
    alias: MobileApp1FooterPaZ2PgCZSfMeta?.alias || [],
    redirect: MobileApp1FooterPaZ2PgCZSfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue").then(m => m.default || m)
  },
  {
    name: MobileApp1Hero37pR3Tg1avMeta?.name ?? "landing-mobile-app-1-components-MobileApp1Hero",
    path: MobileApp1Hero37pR3Tg1avMeta?.path ?? "/landing/mobile-app-1/components/MobileApp1Hero",
    meta: MobileApp1Hero37pR3Tg1avMeta || {},
    alias: MobileApp1Hero37pR3Tg1avMeta?.alias || [],
    redirect: MobileApp1Hero37pR3Tg1avMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue").then(m => m.default || m)
  },
  {
    name: ProcessSwiperC8jj8jbHIoMeta?.name ?? "landing-mobile-app-1-components-ProcessSwiper",
    path: ProcessSwiperC8jj8jbHIoMeta?.path ?? "/landing/mobile-app-1/components/ProcessSwiper",
    meta: ProcessSwiperC8jj8jbHIoMeta || {},
    alias: ProcessSwiperC8jj8jbHIoMeta?.alias || [],
    redirect: ProcessSwiperC8jj8jbHIoMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/ProcessSwiper.vue").then(m => m.default || m)
  },
  {
    name: TestimonialAkWm8bcQvqMeta?.name ?? "landing-mobile-app-1-components-Testimonial",
    path: TestimonialAkWm8bcQvqMeta?.path ?? "/landing/mobile-app-1/components/Testimonial",
    meta: TestimonialAkWm8bcQvqMeta || {},
    alias: TestimonialAkWm8bcQvqMeta?.alias || [],
    redirect: TestimonialAkWm8bcQvqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardNgYLJU4FLjMeta?.name ?? "landing-mobile-app-1-components-TestimonialCard",
    path: TestimonialCardNgYLJU4FLjMeta?.path ?? "/landing/mobile-app-1/components/TestimonialCard",
    meta: TestimonialCardNgYLJU4FLjMeta || {},
    alias: TestimonialCardNgYLJU4FLjMeta?.alias || [],
    redirect: TestimonialCardNgYLJU4FLjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: datacxemZ1xmY6Meta?.name ?? "landing-mobile-app-1-data",
    path: datacxemZ1xmY6Meta?.path ?? "/landing/mobile-app-1/data",
    meta: datacxemZ1xmY6Meta || {},
    alias: datacxemZ1xmY6Meta?.alias || [],
    redirect: datacxemZ1xmY6Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/data.ts").then(m => m.default || m)
  },
  {
    name: index6W3I67s9U3Meta?.name ?? "landing-mobile-app-1",
    path: index6W3I67s9U3Meta?.path ?? "/landing/mobile-app-1",
    meta: index6W3I67s9U3Meta || {},
    alias: index6W3I67s9U3Meta?.alias || [],
    redirect: index6W3I67s9U3Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/index.vue").then(m => m.default || m)
  },
  {
    name: AnchorNavigationwDseYyWOLTMeta?.name ?? "landing-mobile-app-1-ThemeComponents-AnchorNavigation",
    path: AnchorNavigationwDseYyWOLTMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    meta: AnchorNavigationwDseYyWOLTMeta || {},
    alias: AnchorNavigationwDseYyWOLTMeta?.alias || [],
    redirect: AnchorNavigationwDseYyWOLTMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue").then(m => m.default || m)
  },
  {
    name: Breadcrumb6UXKv5aSIkMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Breadcrumb",
    path: Breadcrumb6UXKv5aSIkMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    meta: Breadcrumb6UXKv5aSIkMeta || {},
    alias: Breadcrumb6UXKv5aSIkMeta?.alias || [],
    redirect: Breadcrumb6UXKv5aSIkMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue").then(m => m.default || m)
  },
  {
    name: CustomSyntaxXUSMhow3lVMeta?.name ?? "landing-mobile-app-1-ThemeComponents-CustomSyntax",
    path: CustomSyntaxXUSMhow3lVMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    meta: CustomSyntaxXUSMhow3lVMeta || {},
    alias: CustomSyntaxXUSMhow3lVMeta?.alias || [],
    redirect: CustomSyntaxXUSMhow3lVMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue").then(m => m.default || m)
  },
  {
    name: JarallaxbrG4TgcMfnMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Jarallax",
    path: JarallaxbrG4TgcMfnMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/Jarallax",
    meta: JarallaxbrG4TgcMfnMeta || {},
    alias: JarallaxbrG4TgcMfnMeta?.alias || [],
    redirect: JarallaxbrG4TgcMfnMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue").then(m => m.default || m)
  },
  {
    name: LightGalleryUfORTN8mReMeta?.name ?? "landing-mobile-app-1-ThemeComponents-LightGallery",
    path: LightGalleryUfORTN8mReMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/LightGallery",
    meta: LightGalleryUfORTN8mReMeta || {},
    alias: LightGalleryUfORTN8mReMeta?.alias || [],
    redirect: LightGalleryUfORTN8mReMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue").then(m => m.default || m)
  },
  {
    name: LoadingSpinneryXCRXt2XMjMeta?.name ?? "landing-mobile-app-1-ThemeComponents-LoadingSpinner",
    path: LoadingSpinneryXCRXt2XMjMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    meta: LoadingSpinneryXCRXt2XMjMeta || {},
    alias: LoadingSpinneryXCRXt2XMjMeta?.alias || [],
    redirect: LoadingSpinneryXCRXt2XMjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue").then(m => m.default || m)
  },
  {
    name: Parallaxysl9UdEqhiMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Parallax",
    path: Parallaxysl9UdEqhiMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/Parallax",
    meta: Parallaxysl9UdEqhiMeta || {},
    alias: Parallaxysl9UdEqhiMeta?.alias || [],
    redirect: Parallaxysl9UdEqhiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue").then(m => m.default || m)
  },
  {
    name: PasswordInputJYtyFtt34IMeta?.name ?? "landing-mobile-app-1-ThemeComponents-PasswordInput",
    path: PasswordInputJYtyFtt34IMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/PasswordInput",
    meta: PasswordInputJYtyFtt34IMeta || {},
    alias: PasswordInputJYtyFtt34IMeta?.alias || [],
    redirect: PasswordInputJYtyFtt34IMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue").then(m => m.default || m)
  },
  {
    name: RellaxDD0xbLZC11Meta?.name ?? "landing-mobile-app-1-ThemeComponents-Rellax",
    path: RellaxDD0xbLZC11Meta?.path ?? "/landing/mobile-app-1/ThemeComponents/Rellax",
    meta: RellaxDD0xbLZC11Meta || {},
    alias: RellaxDD0xbLZC11Meta?.alias || [],
    redirect: RellaxDD0xbLZC11Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue").then(m => m.default || m)
  },
  {
    name: VanillaTiltOeRprUK83yMeta?.name ?? "landing-mobile-app-1-ThemeComponents-VanillaTilt",
    path: VanillaTiltOeRprUK83yMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    meta: VanillaTiltOeRprUK83yMeta || {},
    alias: VanillaTiltOeRprUK83yMeta?.alias || [],
    redirect: VanillaTiltOeRprUK83yMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue").then(m => m.default || m)
  },
  {
    name: VideoPopupFKVP7RsGMYMeta?.name ?? "landing-mobile-app-1-ThemeComponents-VideoPopup",
    path: VideoPopupFKVP7RsGMYMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/VideoPopup",
    meta: VideoPopupFKVP7RsGMYMeta || {},
    alias: VideoPopupFKVP7RsGMYMeta?.alias || [],
    redirect: VideoPopupFKVP7RsGMYMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue").then(m => m.default || m)
  },
  {
    name: ContactaPhggewb0jMeta?.name ?? "medical-components-Contact",
    path: ContactaPhggewb0jMeta?.path ?? "/medical/components/Contact",
    meta: ContactaPhggewb0jMeta || {},
    alias: ContactaPhggewb0jMeta?.alias || [],
    redirect: ContactaPhggewb0jMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: Ctajn6TKnjEzUMeta?.name ?? "medical-components-Cta",
    path: Ctajn6TKnjEzUMeta?.path ?? "/medical/components/Cta",
    meta: Ctajn6TKnjEzUMeta || {},
    alias: Ctajn6TKnjEzUMeta?.alias || [],
    redirect: Ctajn6TKnjEzUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: Featurefhodx2c0GKMeta?.name ?? "medical-components-Feature",
    path: Featurefhodx2c0GKMeta?.path ?? "/medical/components/Feature",
    meta: Featurefhodx2c0GKMeta || {},
    alias: Featurefhodx2c0GKMeta?.alias || [],
    redirect: Featurefhodx2c0GKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Feature.vue").then(m => m.default || m)
  },
  {
    name: FeatureCardrtkLUuAKaNMeta?.name ?? "medical-components-FeatureCard",
    path: FeatureCardrtkLUuAKaNMeta?.path ?? "/medical/components/FeatureCard",
    meta: FeatureCardrtkLUuAKaNMeta || {},
    alias: FeatureCardrtkLUuAKaNMeta?.alias || [],
    redirect: FeatureCardrtkLUuAKaNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/FeatureCard.vue").then(m => m.default || m)
  },
  {
    name: MedicalFootertFnH1bLVcKMeta?.name ?? "medical-components-MedicalFooter",
    path: MedicalFootertFnH1bLVcKMeta?.path ?? "/medical/components/MedicalFooter",
    meta: MedicalFootertFnH1bLVcKMeta || {},
    alias: MedicalFootertFnH1bLVcKMeta?.alias || [],
    redirect: MedicalFootertFnH1bLVcKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/MedicalFooter.vue").then(m => m.default || m)
  },
  {
    name: MedicalHeroUqH5Bb2JhpMeta?.name ?? "medical-components-MedicalHero",
    path: MedicalHeroUqH5Bb2JhpMeta?.path ?? "/medical/components/MedicalHero",
    meta: MedicalHeroUqH5Bb2JhpMeta || {},
    alias: MedicalHeroUqH5Bb2JhpMeta?.alias || [],
    redirect: MedicalHeroUqH5Bb2JhpMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/MedicalHero.vue").then(m => m.default || m)
  },
  {
    name: NewsQegkze6Vl5Meta?.name ?? "medical-components-News",
    path: NewsQegkze6Vl5Meta?.path ?? "/medical/components/News",
    meta: NewsQegkze6Vl5Meta || {},
    alias: NewsQegkze6Vl5Meta?.alias || [],
    redirect: NewsQegkze6Vl5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/News.vue").then(m => m.default || m)
  },
  {
    name: NewsCard8ZL1j6MSc4Meta?.name ?? "medical-components-NewsCard",
    path: NewsCard8ZL1j6MSc4Meta?.path ?? "/medical/components/NewsCard",
    meta: NewsCard8ZL1j6MSc4Meta || {},
    alias: NewsCard8ZL1j6MSc4Meta?.alias || [],
    redirect: NewsCard8ZL1j6MSc4Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/NewsCard.vue").then(m => m.default || m)
  },
  {
    name: ServiceCardcdZ5dBUwUHMeta?.name ?? "medical-components-ServiceCard",
    path: ServiceCardcdZ5dBUwUHMeta?.path ?? "/medical/components/ServiceCard",
    meta: ServiceCardcdZ5dBUwUHMeta || {},
    alias: ServiceCardcdZ5dBUwUHMeta?.alias || [],
    redirect: ServiceCardcdZ5dBUwUHMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/ServiceCard.vue").then(m => m.default || m)
  },
  {
    name: ServicesvGQbkaejZoMeta?.name ?? "medical-components-Services",
    path: ServicesvGQbkaejZoMeta?.path ?? "/medical/components/Services",
    meta: ServicesvGQbkaejZoMeta || {},
    alias: ServicesvGQbkaejZoMeta?.alias || [],
    redirect: ServicesvGQbkaejZoMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Services.vue").then(m => m.default || m)
  },
  {
    name: TeamQia7Naa5ysMeta?.name ?? "medical-components-Team",
    path: TeamQia7Naa5ysMeta?.path ?? "/medical/components/Team",
    meta: TeamQia7Naa5ysMeta || {},
    alias: TeamQia7Naa5ysMeta?.alias || [],
    redirect: TeamQia7Naa5ysMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Team.vue").then(m => m.default || m)
  },
  {
    name: TeamCardzujrHvary1Meta?.name ?? "medical-components-TeamCard",
    path: TeamCardzujrHvary1Meta?.path ?? "/medical/components/TeamCard",
    meta: TeamCardzujrHvary1Meta || {},
    alias: TeamCardzujrHvary1Meta?.alias || [],
    redirect: TeamCardzujrHvary1Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/TeamCard.vue").then(m => m.default || m)
  },
  {
    name: Testimonial4vIcCDLRhjMeta?.name ?? "medical-components-Testimonial",
    path: Testimonial4vIcCDLRhjMeta?.path ?? "/medical/components/Testimonial",
    meta: Testimonial4vIcCDLRhjMeta || {},
    alias: Testimonial4vIcCDLRhjMeta?.alias || [],
    redirect: Testimonial4vIcCDLRhjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardUr6WguaePGMeta?.name ?? "medical-components-TestimonialCard",
    path: TestimonialCardUr6WguaePGMeta?.path ?? "/medical/components/TestimonialCard",
    meta: TestimonialCardUr6WguaePGMeta || {},
    alias: TestimonialCardUr6WguaePGMeta?.alias || [],
    redirect: TestimonialCardUr6WguaePGMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: VideoReelWq7uN6Op56Meta?.name ?? "medical-components-VideoReel",
    path: VideoReelWq7uN6Op56Meta?.path ?? "/medical/components/VideoReel",
    meta: VideoReelWq7uN6Op56Meta || {},
    alias: VideoReelWq7uN6Op56Meta?.alias || [],
    redirect: VideoReelWq7uN6Op56Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/components/VideoReel.vue").then(m => m.default || m)
  },
  {
    name: dataKJrwwZBk0MMeta?.name ?? "medical-data",
    path: dataKJrwwZBk0MMeta?.path ?? "/medical/data",
    meta: dataKJrwwZBk0MMeta || {},
    alias: dataKJrwwZBk0MMeta?.alias || [],
    redirect: dataKJrwwZBk0MMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/data.ts").then(m => m.default || m)
  },
  {
    name: indexfLVrcpIDXUMeta?.name ?? "medical",
    path: indexfLVrcpIDXUMeta?.path ?? "/medical",
    meta: indexfLVrcpIDXUMeta || {},
    alias: indexfLVrcpIDXUMeta?.alias || [],
    redirect: indexfLVrcpIDXUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/index.vue").then(m => m.default || m)
  },
  {
    name: typezKYfU0hwd9Meta?.name ?? "medical-type",
    path: typezKYfU0hwd9Meta?.path ?? "/medical/type",
    meta: typezKYfU0hwd9Meta || {},
    alias: typezKYfU0hwd9Meta?.alias || [],
    redirect: typezKYfU0hwd9Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/medical/type.ts").then(m => m.default || m)
  },
  {
    name: ArticleCardYOZB2xuo86Meta?.name ?? "news-id-components-ArticleCard",
    path: ArticleCardYOZB2xuo86Meta?.path ?? "/news/:id()/components/ArticleCard",
    meta: ArticleCardYOZB2xuo86Meta || {},
    alias: ArticleCardYOZB2xuo86Meta?.alias || [],
    redirect: ArticleCardYOZB2xuo86Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/ArticleCard.vue").then(m => m.default || m)
  },
  {
    name: Articlesm8k1xh564aMeta?.name ?? "news-id-components-Articles",
    path: Articlesm8k1xh564aMeta?.path ?? "/news/:id()/components/Articles",
    meta: Articlesm8k1xh564aMeta || {},
    alias: Articlesm8k1xh564aMeta?.alias || [],
    redirect: Articlesm8k1xh564aMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Articles.vue").then(m => m.default || m)
  },
  {
    name: CommentQ7ecNxEa37Meta?.name ?? "news-id-components-Comment",
    path: CommentQ7ecNxEa37Meta?.path ?? "/news/:id()/components/Comment",
    meta: CommentQ7ecNxEa37Meta || {},
    alias: CommentQ7ecNxEa37Meta?.alias || [],
    redirect: CommentQ7ecNxEa37Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Comment.vue").then(m => m.default || m)
  },
  {
    name: CommentFormCiavfvlNM3Meta?.name ?? "news-id-components-CommentForm",
    path: CommentFormCiavfvlNM3Meta?.path ?? "/news/:id()/components/CommentForm",
    meta: CommentFormCiavfvlNM3Meta || {},
    alias: CommentFormCiavfvlNM3Meta?.alias || [],
    redirect: CommentFormCiavfvlNM3Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/CommentForm.vue").then(m => m.default || m)
  },
  {
    name: ContentucFz86jA9LMeta?.name ?? "news-id-components-Content",
    path: ContentucFz86jA9LMeta?.path ?? "/news/:id()/components/Content",
    meta: ContentucFz86jA9LMeta || {},
    alias: ContentucFz86jA9LMeta?.alias || [],
    redirect: ContentucFz86jA9LMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: JarallaxoUnu2qeHHqMeta?.name ?? "news-id-components-Jarallax",
    path: JarallaxoUnu2qeHHqMeta?.path ?? "/news/:id()/components/Jarallax",
    meta: JarallaxoUnu2qeHHqMeta || {},
    alias: JarallaxoUnu2qeHHqMeta?.alias || [],
    redirect: JarallaxoUnu2qeHHqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/Jarallax.vue").then(m => m.default || m)
  },
  {
    name: LightGalleryoIeZoF0blOMeta?.name ?? "news-id-components-LightGallery",
    path: LightGalleryoIeZoF0blOMeta?.path ?? "/news/:id()/components/LightGallery",
    meta: LightGalleryoIeZoF0blOMeta || {},
    alias: LightGalleryoIeZoF0blOMeta?.alias || [],
    redirect: LightGalleryoIeZoF0blOMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/LightGallery.vue").then(m => m.default || m)
  },
  {
    name: SinglePostFooterpyZaeOw0o1Meta?.name ?? "news-id-components-SinglePostFooter",
    path: SinglePostFooterpyZaeOw0o1Meta?.path ?? "/news/:id()/components/SinglePostFooter",
    meta: SinglePostFooterpyZaeOw0o1Meta || {},
    alias: SinglePostFooterpyZaeOw0o1Meta?.alias || [],
    redirect: SinglePostFooterpyZaeOw0o1Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/SinglePostFooter.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHeroPDpohIF5sKMeta?.name ?? "news-id-components-SinglePostHero",
    path: SinglePostHeroPDpohIF5sKMeta?.path ?? "/news/:id()/components/SinglePostHero",
    meta: SinglePostHeroPDpohIF5sKMeta || {},
    alias: SinglePostHeroPDpohIF5sKMeta?.alias || [],
    redirect: SinglePostHeroPDpohIF5sKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: dataKKjKoarGMSMeta?.name ?? "news-id-data",
    path: dataKKjKoarGMSMeta?.path ?? "/news/:id()/data",
    meta: dataKKjKoarGMSMeta || {},
    alias: dataKKjKoarGMSMeta?.alias || [],
    redirect: dataKKjKoarGMSMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/data.ts").then(m => m.default || m)
  },
  {
    name: indexOGJo0O8J1QMeta?.name ?? "news-id",
    path: indexOGJo0O8J1QMeta?.path ?? "/news/:id()",
    meta: indexOGJo0O8J1QMeta || {},
    alias: indexOGJo0O8J1QMeta?.alias || [],
    redirect: indexOGJo0O8J1QMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: type0icyoWtbYsMeta?.name ?? "news-id-type",
    path: type0icyoWtbYsMeta?.path ?? "/news/:id()/type",
    meta: type0icyoWtbYsMeta || {},
    alias: type0icyoWtbYsMeta?.alias || [],
    redirect: type0icyoWtbYsMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/[id]/type.ts").then(m => m.default || m)
  },
  {
    name: BlogCardc0Brq5M7rMMeta?.name ?? "news-components-BlogCard",
    path: BlogCardc0Brq5M7rMMeta?.path ?? "/news/components/BlogCard",
    meta: BlogCardc0Brq5M7rMMeta || {},
    alias: BlogCardc0Brq5M7rMMeta?.alias || [],
    redirect: BlogCardc0Brq5M7rMMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/BlogCard.vue").then(m => m.default || m)
  },
  {
    name: ContentpXUeX3oZT0Meta?.name ?? "news-components-Content",
    path: ContentpXUeX3oZT0Meta?.path ?? "/news/components/Content",
    meta: ContentpXUeX3oZT0Meta || {},
    alias: ContentpXUeX3oZT0Meta?.alias || [],
    redirect: ContentpXUeX3oZT0Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/Content.vue").then(m => m.default || m)
  },
  {
    name: Footer26Zeh9nKY1Meta?.name ?? "news-components-Footer",
    path: Footer26Zeh9nKY1Meta?.path ?? "/news/components/Footer",
    meta: Footer26Zeh9nKY1Meta || {},
    alias: Footer26Zeh9nKY1Meta?.alias || [],
    redirect: Footer26Zeh9nKY1Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/Footer.vue").then(m => m.default || m)
  },
  {
    name: Pagination0a9pfXak91Meta?.name ?? "news-components-Pagination",
    path: Pagination0a9pfXak91Meta?.path ?? "/news/components/Pagination",
    meta: Pagination0a9pfXak91Meta || {},
    alias: Pagination0a9pfXak91Meta?.alias || [],
    redirect: Pagination0a9pfXak91Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/components/Pagination.vue").then(m => m.default || m)
  },
  {
    name: datarlhljOwRtyMeta?.name ?? "news-data",
    path: datarlhljOwRtyMeta?.path ?? "/news/data",
    meta: datarlhljOwRtyMeta || {},
    alias: datarlhljOwRtyMeta?.alias || [],
    redirect: datarlhljOwRtyMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/data.ts").then(m => m.default || m)
  },
  {
    name: index1bOKMHPxi9Meta?.name ?? "news",
    path: index1bOKMHPxi9Meta?.path ?? "/news",
    meta: index1bOKMHPxi9Meta || {},
    alias: index1bOKMHPxi9Meta?.alias || [],
    redirect: index1bOKMHPxi9Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: single_45newsBV2w6O5DbXMeta?.name ?? "news-single-news",
    path: single_45newsBV2w6O5DbXMeta?.path ?? "/news/single-news",
    meta: single_45newsBV2w6O5DbXMeta || {},
    alias: single_45newsBV2w6O5DbXMeta?.alias || [],
    redirect: single_45newsBV2w6O5DbXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/single-news.vue").then(m => m.default || m)
  },
  {
    name: typemwk5bmaBpAMeta?.name ?? "news-type",
    path: typemwk5bmaBpAMeta?.path ?? "/news/type",
    meta: typemwk5bmaBpAMeta || {},
    alias: typemwk5bmaBpAMeta?.alias || [],
    redirect: typemwk5bmaBpAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/news/type.ts").then(m => m.default || m)
  },
  {
    name: indexJplcLFA6IcMeta?.name ?? "partners",
    path: indexJplcLFA6IcMeta?.path ?? "/partners",
    meta: indexJplcLFA6IcMeta || {},
    alias: indexJplcLFA6IcMeta?.alias || [],
    redirect: indexJplcLFA6IcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indexRIeHDHC8WmMeta?.name ?? "payment-redirect",
    path: indexRIeHDHC8WmMeta?.path ?? "/payment/redirect",
    meta: indexRIeHDHC8WmMeta || {},
    alias: indexRIeHDHC8WmMeta?.alias || [],
    redirect: indexRIeHDHC8WmMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/payment/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: ContentTZiZN8C7tFMeta?.name ?? "privacy-policy-components-Content",
    path: ContentTZiZN8C7tFMeta?.path ?? "/privacy-policy/components/Content",
    meta: ContentTZiZN8C7tFMeta || {},
    alias: ContentTZiZN8C7tFMeta?.alias || [],
    redirect: ContentTZiZN8C7tFMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/privacy-policy/components/Content.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHerosL6ET8aAZKMeta?.name ?? "privacy-policy-components-SinglePostHero",
    path: SinglePostHerosL6ET8aAZKMeta?.path ?? "/privacy-policy/components/SinglePostHero",
    meta: SinglePostHerosL6ET8aAZKMeta || {},
    alias: SinglePostHerosL6ET8aAZKMeta?.alias || [],
    redirect: SinglePostHerosL6ET8aAZKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/privacy-policy/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: indexyktl2hnjHDMeta?.name ?? "privacy-policy",
    path: indexyktl2hnjHDMeta?.path ?? "/privacy-policy",
    meta: indexyktl2hnjHDMeta || {},
    alias: indexyktl2hnjHDMeta?.alias || [],
    redirect: indexyktl2hnjHDMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexk3wqVjbVzyMeta?.name ?? "sales-agent-form",
    path: indexk3wqVjbVzyMeta?.path ?? "/sales-agent-form",
    meta: indexk3wqVjbVzyMeta || {},
    alias: indexk3wqVjbVzyMeta?.alias || [],
    redirect: indexk3wqVjbVzyMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/sales-agent-form/index.vue").then(m => m.default || m)
  },
  {
    name: indexKataz2yIGcMeta?.name ?? "sales",
    path: indexKataz2yIGcMeta?.path ?? "/sales",
    meta: indexKataz2yIGcMeta || {},
    alias: indexKataz2yIGcMeta?.alias || [],
    redirect: indexKataz2yIGcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSFJVDXKVoMeta?.name ?? "success",
    path: indexxSFJVDXKVoMeta?.path ?? "/success",
    meta: indexxSFJVDXKVoMeta || {},
    alias: indexxSFJVDXKVoMeta?.alias || [],
    redirect: indexxSFJVDXKVoMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexA9XwmUiBdvMeta?.name ?? "tamara",
    path: indexA9XwmUiBdvMeta?.path ?? "/tamara",
    meta: indexA9XwmUiBdvMeta || {},
    alias: indexA9XwmUiBdvMeta?.alias || [],
    redirect: indexA9XwmUiBdvMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/tamara/index.vue").then(m => m.default || m)
  },
  {
    name: Content3q6LB5aO59Meta?.name ?? "terms-and-condition-components-Content",
    path: Content3q6LB5aO59Meta?.path ?? "/terms-and-condition/components/Content",
    meta: Content3q6LB5aO59Meta || {},
    alias: Content3q6LB5aO59Meta?.alias || [],
    redirect: Content3q6LB5aO59Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/terms-and-condition/components/Content.vue").then(m => m.default || m)
  },
  {
    name: indexykjMaxEAVLMeta?.name ?? "terms-and-condition",
    path: indexykjMaxEAVLMeta?.path ?? "/terms-and-condition",
    meta: indexykjMaxEAVLMeta || {},
    alias: indexykjMaxEAVLMeta?.alias || [],
    redirect: indexykjMaxEAVLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/terms-and-condition/index.vue").then(m => m.default || m)
  },
  {
    name: index2PEWJ7TmqLMeta?.name ?? "trim",
    path: index2PEWJ7TmqLMeta?.path ?? "/trim",
    meta: index2PEWJ7TmqLMeta || {},
    alias: index2PEWJ7TmqLMeta?.alias || [],
    redirect: index2PEWJ7TmqLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/trim/index.vue").then(m => m.default || m)
  },
  {
    name: indexNlDbLb6YT0Meta?.name ?? "warranties",
    path: indexNlDbLb6YT0Meta?.path ?? "/warranties",
    meta: indexNlDbLb6YT0Meta || {},
    alias: indexNlDbLb6YT0Meta?.alias || [],
    redirect: indexNlDbLb6YT0Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranties/index.vue").then(m => m.default || m)
  },
  {
    name: indexuP8U8ZsLFvMeta?.name ?? "warranty-form",
    path: indexuP8U8ZsLFvMeta?.path ?? "/warranty-form",
    meta: indexuP8U8ZsLFvMeta || {},
    alias: indexuP8U8ZsLFvMeta?.alias || [],
    redirect: indexuP8U8ZsLFvMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty-form/index.vue").then(m => m.default || m)
  },
  {
    name: AppFeatureHcWzPAkIQPMeta?.name ?? "warranty-components-AppFeature",
    path: AppFeatureHcWzPAkIQPMeta?.path ?? "/warranty/components/AppFeature",
    meta: AppFeatureHcWzPAkIQPMeta || {},
    alias: AppFeatureHcWzPAkIQPMeta?.alias || [],
    redirect: AppFeatureHcWzPAkIQPMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/AppFeature.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBox1YQTU79SXbMeta?.name ?? "warranty-components-CarSearchBox",
    path: CarSearchBox1YQTU79SXbMeta?.path ?? "/warranty/components/CarSearchBox",
    meta: CarSearchBox1YQTU79SXbMeta || {},
    alias: CarSearchBox1YQTU79SXbMeta?.alias || [],
    redirect: CarSearchBox1YQTU79SXbMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: Pricingt9IiZOJvpiMeta?.name ?? "warranty-components-Pricing",
    path: Pricingt9IiZOJvpiMeta?.path ?? "/warranty/components/Pricing",
    meta: Pricingt9IiZOJvpiMeta || {},
    alias: Pricingt9IiZOJvpiMeta?.alias || [],
    redirect: Pricingt9IiZOJvpiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: PricingForMobilehFuSPKCcfxMeta?.name ?? "warranty-components-PricingForMobile",
    path: PricingForMobilehFuSPKCcfxMeta?.path ?? "/warranty/components/PricingForMobile",
    meta: PricingForMobilehFuSPKCcfxMeta || {},
    alias: PricingForMobilehFuSPKCcfxMeta?.alias || [],
    redirect: PricingForMobilehFuSPKCcfxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/PricingForMobile.vue").then(m => m.default || m)
  },
  {
    name: Saas3Hero1bRly4dBepMeta?.name ?? "warranty-components-Saas3Hero",
    path: Saas3Hero1bRly4dBepMeta?.path ?? "/warranty/components/Saas3Hero",
    meta: Saas3Hero1bRly4dBepMeta || {},
    alias: Saas3Hero1bRly4dBepMeta?.alias || [],
    redirect: Saas3Hero1bRly4dBepMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Saas3Hero.vue").then(m => m.default || m)
  },
  {
    name: TestimonialZ4GRC9BlsQMeta?.name ?? "warranty-components-Testimonial",
    path: TestimonialZ4GRC9BlsQMeta?.path ?? "/warranty/components/Testimonial",
    meta: TestimonialZ4GRC9BlsQMeta || {},
    alias: TestimonialZ4GRC9BlsQMeta?.alias || [],
    redirect: TestimonialZ4GRC9BlsQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCard5yuzianWhEMeta?.name ?? "warranty-components-TestimonialCard",
    path: TestimonialCard5yuzianWhEMeta?.path ?? "/warranty/components/TestimonialCard",
    meta: TestimonialCard5yuzianWhEMeta || {},
    alias: TestimonialCard5yuzianWhEMeta?.alias || [],
    redirect: TestimonialCard5yuzianWhEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: WorkHQw6opPSNKMeta?.name ?? "warranty-components-Work",
    path: WorkHQw6opPSNKMeta?.path ?? "/warranty/components/Work",
    meta: WorkHQw6opPSNKMeta || {},
    alias: WorkHQw6opPSNKMeta?.alias || [],
    redirect: WorkHQw6opPSNKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/components/Work.vue").then(m => m.default || m)
  },
  {
    name: datafD3ov4XKAAMeta?.name ?? "warranty-data",
    path: datafD3ov4XKAAMeta?.path ?? "/warranty/data",
    meta: datafD3ov4XKAAMeta || {},
    alias: datafD3ov4XKAAMeta?.alias || [],
    redirect: datafD3ov4XKAAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/data.ts").then(m => m.default || m)
  },
  {
    name: indexfkl1g81zKxMeta?.name ?? "warranty",
    path: indexfkl1g81zKxMeta?.path ?? "/warranty",
    meta: indexfkl1g81zKxMeta || {},
    alias: indexfkl1g81zKxMeta?.alias || [],
    redirect: indexfkl1g81zKxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: typeJJ0IusI8daMeta?.name ?? "warranty-type",
    path: typeJJ0IusI8daMeta?.path ?? "/warranty/type",
    meta: typeJJ0IusI8daMeta || {},
    alias: typeJJ0IusI8daMeta?.alias || [],
    redirect: typeJJ0IusI8daMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/cartrust-website/pages/warranty/type.ts").then(m => m.default || m)
  }
]